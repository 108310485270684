import AppComp from './App';
import BackgroundSelectorComp from './BackgroundSelector/BackgroundSelector';

import CarouselComp from './Carousel/Carousel';
import CarouselPaneComp from './CarouselPane/CarouselPane';

import DownloadBoxComp from './DownloadBox/DownloadBox';
import ErrorScreenComp from './ErrorScreen/ErrorScreen';

import GalleryComp from './Gallery/Gallery';
import GalleryPostComp from './GalleryPost/GalleryPost';
import GalleryPostPopupComp from './GalleryPostPopup/GalleryPostPopup';

import ImageDisplayComp from './ImageDisplay/ImageDisplay';
import ImageFrameComp from './ImageFrame/ImageFrame';
import ImageSelectorComp from './ImageSelector/ImageSelector';

import LocaleSelectorComp from './LocaleSelector/LocaleSelector';
import LoadingScreenComp from './LoadingScreen/LoadingScreen';
import ScrollingBackgroundComp from './ScrollingBackground/ScrollingBackground';
import SliderComp from './Slider/Slider';
import StartScreenComp from './StartScreen/StartScreen';
import TextInputComp from './TextInput/TextInput';
import TextModalComp from './TextModal/TextModal';
import WorkBenchComp from './WorkBench/WorkBench';

import SharingButtonsComp from './SharingButtons/SharingButtons';

export const App = AppComp;
export const BackgroundSelector = BackgroundSelectorComp;

export const Carousel = CarouselComp;
export const CarouselPane = CarouselPaneComp;

export const DownloadBox = DownloadBoxComp;
export const ErrorScreen = ErrorScreenComp;

export const Gallery = GalleryComp;
export const GalleryPost = GalleryPostComp;
export const GalleryPostPopup = GalleryPostPopupComp;

export const ImageDisplay = ImageDisplayComp;
export const ImageFrame = ImageFrameComp;
export const ImageSelector = ImageSelectorComp;

export const LocaleSelector = LocaleSelectorComp;
export const LoadingScreen = LoadingScreenComp;
export const ScrollingBackground = ScrollingBackgroundComp;
export const Slider = SliderComp;
export const StartScreen = StartScreenComp;
export const TextInput = TextInputComp;
export const TextModal = TextModalComp;
export const WorkBench = WorkBenchComp;

export const SharingButtons = SharingButtonsComp;
