import styled from 'styled-components';

const RestartIcon = styled.div`
  height: 20px;
  width: 20px;
  position: relative;

  &::before {
    border: 1px solid black;
    border-color: transparent black black black;
    border-radius: 50%;
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    top: 0;
    left: 0;
    transform: rotate(45deg);
  }

  &::after {
    content: '';
    border: 1px solid black;
    border-color: black black transparent transparent;
    position: absolute;
    top: 1px;
    right: 50%;
    height: 4px;
    width: 4px;
    transform: translateY(-50%) rotate(45deg);
  }
`;

export default RestartIcon;
