import React from 'react';
import { connect } from 'react-redux';

import { Title, Section } from './TextModal.style';

const About = ({ locale }) => {
  switch (locale) {
    case 'en':
      return (
        <React.Fragment>
          <Title>What is #ColorMixology?</Title>

          <Section>
            <p>Color Mixology by Primience. </p>
          </Section>

          <Section>
            <p>DJ. Artist. Mother. </p>
          </Section>

          <Section>
            <p>Philosopher. Writer. Makeup Artist. </p>
          </Section>

          <Section>
            <p>Everyone has more than just one side.</p>
            <p>
              This tool lets you create a portrait that shows all shades of you: the expected, the
              surprising, the passionate you.
            </p>
          </Section>

          <Section>
            <p>
              Shiseido Professional believes in living life in full color, in giving everyone the
              confidence to try new things, the things that make up how you live your life.
            </p>
          </Section>

          <Section>
            <p>#ColorMixology #BeAnyColor </p>
          </Section>
        </React.Fragment>
      );

    case 'ja_jp':
      return (
        <React.Fragment>
          <Title>What is #ColorMixology?</Title>

          <Section>
            <p>カラーミクソロジー by プリミエンス </p>
          </Section>

          <Section>
            <p>DJ、アーティスト、母。 </p>
          </Section>

          <Section>
            <p>哲学作家、ライター、コスメマニア。 </p>
          </Section>

          <Section>
            <p>一人ひとり色んな“顔”を持っています。</p>
            <p>みんなが知っているあなた、意外な一面のあなた、情熱的なあなた。</p>
            <p>これはあなたのすべての側面を1枚のポートレートに表現できるツールです。</p>
          </Section>

          <Section>
            <p>
              資生堂プロフェッショナルは、あなたの生活を彩るすべてが、挑戦する自信につながると信じ、“Color”にあふれる人生を応援しています。
            </p>
          </Section>

          <Section>
            <p>#ColorMixology #BeAnyColor </p>
          </Section>
        </React.Fragment>
      );

    case 'ko_kr':
      return (
        <React.Fragment>
          <Title>What is #ColorMixology?</Title>

          <Section>
            <p>#ColorMixology #BeAnyColor </p>
          </Section>
        </React.Fragment>
      );

    case 'zh_cn':
      return (
        <React.Fragment>
          <Title>#ColorMixology是什么？</Title>

          <Section>
            <p>ColorMixology by 普盈丝 </p>
          </Section>

          <Section>
            <p>她是一名DJ，是艺术家，也是日本研究员。 </p>
          </Section>

          <Section>
            <p>她是一位母亲，是旅行作家，也是电台主持。 </p>
          </Section>

          <Section>
            <p>谁都不止一面。</p>
            <p>
              在沙龙染发大师的手下，每种普盈丝染膏也蕴含无限种色彩搭配可能。 “COLORMIXOLOGY”
              号召染发师们晒出你为顾客创造美丽的秘诀。
            </p>
          </Section>

          <Section>
            <p>
              欢迎上传成果照片参与话题分享，让灵感碰撞，更让越来越多的人打破界限，#发色不设限#。
            </p>
          </Section>

          <Section>
            <p>#ColorMixology #BeAnyColor </p>
          </Section>
        </React.Fragment>
      );

    case 'zh_tw':
    case 'zh_hk':
      return (
        <React.Fragment>
          <Title>#ColorMixology是什麼？</Title>

          <Section>
            <p>ColorMixology by 沛迷絲 </p>
          </Section>

          <Section>
            <p>DJ、藝術家、母親。 </p>
          </Section>

          <Section>
            <p>哲學家、作家、彩妝師。 </p>
          </Section>

          <Section>
            <p>誰都不止一面。</p>
            <p>
              別人眼中的你、意想不到的你、充滿熱情的你“COLORMIXOLOGY”為你表達你的每一個與眾不同。
            </p>
          </Section>

          <Section>
            <p>
              資生堂專業美髮，始終堅信，多彩的生命更具活力，始終陪伴，給你信心直面挑戰，讓你的未來，無限精彩！
            </p>
          </Section>

          <Section>
            <p>#ColorMixology　#BeAnyColor </p>
          </Section>
        </React.Fragment>
      );

    default:
      throw Error(`Unrecognized locale: ${locale}`);
  }
};

const mapState = state => ({
  locale: state.locale,
});

export default connect(mapState)(About);
