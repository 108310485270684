import styled from 'styled-components';

const size = 40;

export const Container = styled.div`
  height: ${size}px;
  position: relative;
  width: 100%;
  max-width: 70vw;
  -webkit-user-select: none;

  &::before {
    position: absolute;
    top: ${size / 2 - 1}px;
    left: ${size / 2}px;

    background-color: ${({ disabled }) => (disabled ? 'lightgrey' : 'black')};
    content: '';
    height: 1px;
    opacity: 0.8;
    width: calc(100% - ${size}px);
  }
`;

export const Track = styled.div`
  display: flex;
  width: calc(100% - ${size}px);

  &::before,
  &::after {
    background-color: black;
    border-radius: 50%;
    content: '';
    height: 3px;
    width: 3px;

    position: absolute;
    top: calc(50% - 2px);
  }

  &::before {
    left: 20px;
  }

  &::after {
    right: 20px;
  }
`;

export const Dot = styled.div.attrs(({ position }) => ({
  style: { left: `${position * 100}%` },
}))`
  position: relative;
  height: ${size}px;
  width: ${size}px;

  background-color: ${({ disabled }) => (disabled ? 'lightgrey' : 'white')};
  border-radius: 50%;
  box-shadow: ${({ disabled }) => (disabled ? 'none' : '0 0 10px -2px black')};
  z-index: 10;
`;
