import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 500;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: white;
  overflow: hidden;
  padding: 5%;
`;

export const GeneratedImage = styled.img`
  width: 100%;
`;

export const Text = styled.p`
  color: black;
  margin: 0;
  padding: 0;
  text-align: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
`;

export const SaveButton = styled.div`
  padding: 10px;
  border: 1px solid #000;
`;
