import styled from 'styled-components';

const BackIcon = styled.div`
  height: 20px;
  width: 10px;
  overflow: hidden;
  position: relative;

  &::before {
    border: 1px solid black;
    border-color: ${({ disabled }) =>
      disabled
        ? `lightgrey transparent transparent lightgrey`
        : `black transparent transparent black`};
    content: '';
    position: absolute;
    height: 13px;
    width: 13px;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%) rotate(-45deg);
  }
`;

export default BackIcon;
