import styled from 'styled-components';

import BackIcon from './BackIcon';

const NextIcon = styled(BackIcon)`
  &::before {
    left: 0;
    transform: translate(-50%, -50%) rotate(135deg);
  }
`;

export default NextIcon;
