export default (state = { data: [], loading: false }, { type, payload }) => {
    switch (type) {
      case 'GET_POSTS_PENDING':
        return { ...state, loading: true };
  
      case 'GET_POSTS_FULFILLED':
        return { ...state, data: [...state.data, ...payload], loading: false };
  
      default:
        return state;
    }
  };
  