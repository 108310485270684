import styled from 'styled-components';

export const Box = styled.div`
  flex-shrink: 0;
  padding-bottom: 23%;
  position: relative;
  width: 23%;
`;

export const Square = styled.div`
  background: ${({ background }) => background};
  border: ${({ selected }) => (selected ? '0.55vw solid white' : 'none')};
  border-radius: 50%;
  box-shadow: 0 0 12px -5px black;
  position: absolute;
  height: 84%;
  width: 84%;
  left: 8%;
  top: 8%;
  transition: transform 100ms ease-in-out;
  -webkit-tap-highlight-color: transparent;

  &:active {
    transform: scale(0.95);
  }
`;

export const Container = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    background-color: transparent;
    display: none;
  }
`;
