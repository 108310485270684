import PropTypes from 'prop-types';
import React from 'react';

import gradients from 'assets/gradients.json';
import { Box, Container, Square } from './BackgroundSelector.style';

class Background extends React.PureComponent {
  static propTypes = {
    background: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
  };

  handleClick = () => {
    const { index, onClick } = this.props;
    onClick(index);
  };

  render = () => {
    const { background, selected } = this.props;

    return (
      <Box>
        <Square background={background} onClick={this.handleClick} selected={selected} />
      </Box>
    );
  };
}

const BackgroundSelector = ({ onSelect, selected }) => (
  <Container>
    {gradients.map((gradient, i) => (
      <Background
        key={gradient}
        background={gradient}
        index={i}
        onClick={onSelect}
        selected={selected === i}
      />
    ))}
  </Container>
);

BackgroundSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
};

export default BackgroundSelector;
