import React from 'react';
import { connect } from 'react-redux';

import Facebook from 'react-sharingbuttons/dist/buttons/Facebook';

import 'react-sharingbuttons/dist/main.css';

const SharingButtons = ({url}) => {
    //const shareText = 'Check this site!';

    return (
      <div>
        <Facebook url={url} />
      </div>
    )
  }

  const mapState = state => ({
    locale: state.locale,
  });
  
  export default connect(mapState)(SharingButtons);