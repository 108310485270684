import styled from 'styled-components';

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  background-image: linear-gradient(90deg, #84fab0, #8fd3f4);
  border: none;
  color: white;
  font-size: 4vw;
  font-weight: 600;
  height: 12vw;
  outline: none;
  padding: 0;
  width: 60vw;
`;

export const Input = styled.input`
  display: none;
`;
