import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import copy from 'assets/copy.json';
import { Container, Spinner, Text } from './LoadingScreen.style';

const LoadingScreen = ({ label, locale }) => (
  <Container>
    <Spinner />
    <Text>{`${copy[label][locale]}...`}</Text>
  </Container>
);

LoadingScreen.defaultProps = {
  label: 'loading',
};

LoadingScreen.propTypes = {
  label: PropTypes.string,
  locale: PropTypes.string.isRequired,
};

const mapState = state => ({
  locale: state.locale,
});

export default connect(mapState)(LoadingScreen);
