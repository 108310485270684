import PropTypes from 'prop-types';
import React from 'react';

import prevent from 'utils/prevent';
import { ImageDisplay } from 'components';

class ImageFrame extends React.PureComponent {
  static defaultProps = {
    enabled: false,
  };

  static propTypes = {
    enabled: PropTypes.bool,
    info: PropTypes.shape().isRequired,
    updateAR: PropTypes.func.isRequired,
    updateOffset: PropTypes.func.isRequired,
  };

  state = {
    sx: 0,
    sy: 0,
  };

  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  componentDidMount = () => {
    this.container.current.addEventListener('touchmove', prevent);
  };

  componentWillUnmount = () => {
    this.container.current.removeEventListener('touchmove', prevent);
  };

  componentDidUpdate = prevProps => {
    const { info } = this.props;
    const { scale: prevScale } = prevProps.info.image;
    if (info.image.scale !== prevScale) this.setMaxMovement();
  };

  handleImageLoaded = e => {
    const { updateAR } = this.props;
    const ar = e.target.width / e.target.height;
    updateAR(ar);
    this.setMaxMovement(ar);
  };

  handleTouchStart = e => {
    const { pageX, pageY } = e.targetTouches[0];
    this.setState({ sx: pageX, sy: pageY });
  };

  handleTouchMove = ({ targetTouches: [{ pageX, pageY }] }) => {
    const { info } = this.props;
    const { ar, x, y } = info.image;
    const { sx, sy } = this.state;
    const { clientWidth } = this.container.current;

    this.setXY({
      x: x + (pageX - sx) / clientWidth / Math.max(1, ar),
      y: y + (pageY - sy) / clientWidth / Math.max(1, 1 / ar),
    });
    this.setState({ sx: pageX, sy: pageY });
  };

  setMaxMovement = input => {
    const { info } = this.props;
    const { image } = info;
    const ar = input || image.ar;

    this.setState(
      {
        maxX: (image.scale - Math.min(1, 1 / ar)) / 2,
        maxY: (image.scale - Math.min(1, ar)) / 2,
      },
      () => this.setXY(image)
    );
  };

  setXY = ({ x, y }) => {
    const { info, updateOffset } = this.props;
    const { maxX, maxY } = this.state;
    const { x: oldX, y: oldY } = info.image;

    const newX = Math.max(Math.min(x, maxX), -maxX);
    const newY = Math.max(Math.min(y, maxY), -maxY);
    if (newX !== oldX || newY !== oldY) {
      updateOffset({ x: newX, y: newY });
    }
  };

  render = () => {
    const { enabled, info } = this.props;

    return (
      <div
        onTouchStart={enabled ? this.handleTouchStart : null}
        onTouchMove={enabled ? this.handleTouchMove : null}
        ref={this.container}
        style={{ WebkitUserSelect: 'none', width: '100%' }}
      >
        <ImageDisplay {...info} onLoad={this.handleImageLoaded} />
      </div>
    );
  };
}

export default ImageFrame;
