import BackIconComp from './BackIcon';
import CloseIconComp from './CloseIcon';
import NextIconComp from './NextIcon';
import RestartIconComp from './RestartIcon';
import SaveIconComp from './SaveIcon';
import ShareIconComp from './ShareIcon';

export const BackIcon = BackIconComp;
export const CloseIcon = CloseIconComp;
export const NextIcon = NextIconComp;
export const RestartIcon = RestartIconComp;
export const SaveIcon = SaveIconComp;
export const ShareIcon = ShareIconComp;
