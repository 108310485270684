import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import copy from 'assets/copy.json';
import {
  Container,
  DownloadImage,
  Image,
  Shadow,
  Gradient,
  Texts,
  DownloadText, Text,
  DownloadHashtag, Hashtag,
  Logo, DownloadLogo,
  Product, DownloadProduct
} from './ImageDisplay.style';

const hashtag = '#ColorMixology  #BeAnyColor';

const ImageDisplay = ({ background, bar, download, image, locale, onLoad, shadow, texts }) =>
  download ? (
    <Container download id={bar ? 'download-box' : 'upload-box'}>
      <DownloadImage {...image} />
      <Shadow shadow={shadow} />
      {bar && <Gradient background={background} />}
      <Texts>
        {texts.map(({ id, text }) => (
          <DownloadText key={id} locale={locale}>
            {text}
          </DownloadText>
        ))}
        <DownloadHashtag>{hashtag}</DownloadHashtag>
        <DownloadLogo src="../images/logo-frame.png"></DownloadLogo>
        <DownloadProduct src="../images/product-frame.png"></DownloadProduct>
      </Texts>
    </Container>
  ) : (
    <Container>
      <Image {...image} shadow={shadow} onLoad={onLoad} />
      <Gradient background={background} />
      <Texts>
        {texts.map(({ id, text }, i) => (
          <svg key={id} viewBox="0 0 200 20.6">
            <Text x="3" y="75%" locale={locale} empty={!text}>
              {text || copy.placeholders[locale][i]}
            </Text>
          </svg>
        ))}
        <svg viewBox="0 0 200 12.6">
          <Hashtag x="4" y="75%">
            {hashtag}
          </Hashtag>
        </svg>
        <Logo src="../images/logo-frame.png"></Logo>
        <Product src="../images/product-frame.png"></Product>
      </Texts>
    </Container>
  );

ImageDisplay.defaultProps = {
  bar: false,
  download: false,
  onLoad: undefined,
};

ImageDisplay.propTypes = {
  background: PropTypes.number.isRequired,
  bar: PropTypes.bool,
  download: PropTypes.bool,
  image: PropTypes.shape().isRequired,
  locale: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  shadow: PropTypes.number.isRequired,
  texts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const mapState = state => ({
  locale: state.locale,
});

export default connect(mapState)(ImageDisplay);
