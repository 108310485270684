import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
  position: relative;
  padding-bottom: 100%;
  width: 100%;
`;

export const Image = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  display: block;
  object-fit: cover;
`;

export const Placeholder = styled(Image)`
  left: -1%;
  top: -1%;
  height: 102%;
  width: 102%;

  filter: blur(0.5vw);
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 200ms ease-in-out;
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
`;

export const GradientContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const Gradient = styled.div`
  background: ${({ background }) => background};
  position: absolute;
  left: -1%;
  top: -1%;
  height: 102%;
  width: 102%;
`;

export const Hashtag = styled.p`
  color: white;
  font-size: 4vw;
  font-weight: 700;
  margin: 0;
  padding: 0;
  position: relative;

  @media screen and (min-aspect-ratio: 13/9) {
    font-size: 4vh;
  }
`;
