import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
`;

export const Contents = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 120;

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
`;

export const Description = styled.p`
  color: white;
  font-size: 4vw;
  font-weight: 600;
  padding: 0 7%;
  text-align: center;
  white-space: pre-line;
  -webkit-text-size-adjust: none;
`;

export const LinkButton = styled.span`
  cursor: pointer;
  font-weight: 800;
  text-decoration: underline;
  white-space: nowrap;
`;

export const LinkLink = styled.a`
  color: inherit;
  cursor: pointer;
  font-weight: 800;
  text-decoration: underline;
  white-space: nowrap;
`;

export const LocaleButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  font-family: inherit;
  font-size: 3vw;
  font-weight: 600;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  right: 18px;
  top: 18px;
`;

export const Logo = styled.img`
  width: 40%;
`;

export const Note = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  color: white;
  font-size: 2.6vw;
  padding: 15px 10px;
  text-align: center;
`;
