import html2canvas from 'html2canvas';
import FileSaver from 'file-saver';

export const generate = () => {
  const download = document.querySelector('#download-box');
  const options = {
    allowTaint: true,
    logging: true,
    scale: 5,
    useCORS: true,
  };

  return html2canvas(download, options).then(canvas => canvas.toDataURL());
};

export const download = () =>
  generate().then(blob => {
    FileSaver.saveAs(blob, 'shiseido_primience.png');
  });
