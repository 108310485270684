import PropTypes from 'prop-types';
import React from 'react';

import { CloseButton } from 'components/Buttons';
import { Container } from './TextModal.style';
import About from './About';
import Privacy from './Privacy';
import Terms from './Terms';

const Content = ({ contentType }) => {
  switch (contentType) {
    case 'about':
      return <About />;

    case 'privacy':
      return <Privacy />;

    case 'terms':
      return <Terms />;

    default:
      throw Error('Unrecognized content type');
  }
};

class TextModal extends React.PureComponent {
  static propTypes = {
    contentType: PropTypes.string.isRequired,
    hide: PropTypes.func.isRequired,
  };

  render = () => {
    const { contentType, hide } = this.props;

    return (
      <Container>
        <CloseButton onClick={hide} black />
        <Content contentType={contentType} />
      </Container>
    );
  };
}

export default TextModal;
