import map from 'assets/profanityMap.json';

const traverse = string => {
  const chars = string.split('');
  let root = map;
  while (chars.length > 0) {
    root = root[chars.shift()];
    if (!root) return false;
  }
  if (root.$) return true;
  return false;
};

// const hasProfaniy = string =>
//   Array(string.length)
//     .fill(0)
//     .map((_, i) => string.slice(i).toLowerCase())
//     .some(sub => traverse(sub));

const hasProfaniy = string => string.split(' ').some(sub => traverse(sub.toLowerCase()));

export default hasProfaniy;
