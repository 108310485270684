import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  background: white;
  height: 100vh;
  opacity: 1;
  transition: opacity 200ms ease-in-out;
  visibility: visible;
  width: 100vw;
  z-index: 1000000;

  @media screen and (max-width: 1200px) and (max-aspect-ratio: 13/9) {
    opacity: 0;
    transition: opacity 200ms ease-in-out, visibility 0s 200ms linear;
    visibility: hidden;
  }
`;

export const Messages = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
  width: 100%;
`;

const Message = styled.p`
  color: white;
  font-size: 3vw;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 90%;
  z-index: 100;
`;

export const DesktopMessage = styled(Message)`
  display: block;

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const LandscapeMessage = styled(Message)`
  display: none;

  @media screen and (max-width: 1200px) and (min-aspect-ratio: 13/9) {
    display: block;
  }
`;

export const Posts = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  filter: brightness(0.5);
  grid-auto-flow: column;
  grid-template-rows: repeat(2, 1fr);
  height: 100%;
  min-width: 300vh;
  width: 100%;
`;
