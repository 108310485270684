import PropTypes from 'prop-types';
import React from 'react';
import axios from 'axios';

import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';


import copy from 'assets/copy.json';
import { generate, download } from 'utils/imageSaver';

import {
  BackgroundSelector,
  Carousel,
  CarouselPane,
  ImageSelector,
  LoadingScreen,
  ImageFrame,
  Slider,
  TextInput,
  SharingButtons
} from 'components';
import { BackButton, NextButton, FinishButton, RestartButton, SaveButton, ShareButton } from 'components/Buttons';
import { Container, GeneratedImage, Text, Buttons } from './WorkBench.style';

class WorkBench extends React.PureComponent {
  static defaultProps = {
    visible: false,
  };

  static propTypes = {
    hide: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    updateInfo: PropTypes.func.isRequired,
    visible: PropTypes.bool,
  };

  state = {
    ar: 1,
    background: 0,
    offset: { x: 0, y: 0 },
    scale: 1,
    src: '',
    shadow: 0,
    text1: { profanity: false, text: '' },
    text2: { profanity: false, text: '' },
    text3: { profanity: false, text: '' },

    downloadUrl: '',
    position: 0,
    uploading: false,
  };

  canGoNext = () => {
    const { background, position, src, text1, text2, text3 } = this.state;
    switch (position) {
      /* eslint-disable */
      case 0: return !!src;
      case 2: return !!background;
      case 3: return !!text1.text && !(text1.profanity);
      case 4: return !!text2.text && !(text2.profanity);
      case 5: return !!text3.text && !(text3.profanity);
      default:
        return true;
    } /* eslint-enable */
  };

  grabInfo = () => {
    const { ar, background, offset, scale, src, shadow, text1, text2, text3 } = this.state;
    return {
      background,
      image: { ar, scale, src, ...offset },
      shadow,
      texts: [text1, text2, text3].map(({ text }, id) => ({ id, text })),
    };
  };

  handleUpdate = name => value => {
    this.setState({ [name]: value });
    if (name === 'src') this.setState({ position: 1 });
  };

  handleBack = () => {
    const { hide } = this.props;
    const { position } = this.state;
    if (position === 0) {
      hide();
    } else {
      this.setState({ position: position - 1 });
    }
  };

  handleShare = downloadUrl => {
    if (navigator.share !== undefined) {
      navigator.share({
        title: 'Shiseido Colomixology',
        text: '#ColorMixology #BeAnyColor',
        url: downloadUrl,
      });
    } else {
      alert('รองรับเฉพาะ Chrome for Android / Safari ใน iOS 12.2 ขึ้นไปเท่านั้น \n(ท่านสามารถแชร์ภาพ โดยการใช้ปุ่มแชร์ของเบราเซอร์ที่ท่านกำลังใช้งานอยู่แทนได้)')
    }
  };

  handleNext = () => {
    this.setState(({ position }) => ({ position: position + 1 }));
  };

  upload = async (base64) => {
    return axios.post('https://shiseido-pro.manasoftware.co.th/upload.php', {
      img: base64
    }).then(res => {
      return res.data;
    });
  }

  handleFinish = async () => {
    const { updateInfo } = this.props;
    this.setState({ uploading: true });

    await updateInfo(this.grabInfo());
    //await download();
    let downloadUrl = await generate();

    downloadUrl = await this.upload(downloadUrl);

    this.setState(({ position }) => ({
      position: position + 1,
      uploading: false,
      downloadUrl,
    }));
  };

  handleRestart = () => {
    window.location.reload(true);
  };

  handleDownload = async () => {
    await download();
  };

  render = () => {
    const { locale, visible } = this.props;
    const { background, scale, src, shadow, text1, text2, text3 } = this.state;
    const { downloadUrl, position, uploading } = this.state;
    const canGoNext = this.canGoNext();

    return (
      <Container visible={visible}>
        <Buttons>
          <BackButton onClick={this.handleBack} hide={position === 7} />
          <div />
          <NextButton onClick={this.handleNext} disabled={!canGoNext} hide={position > 5} />
          <FinishButton onClick={this.handleFinish} hide={position !== 6} />
          <RestartButton onClick={this.handleRestart} hide={position !== 7} />
        </Buttons>

        {downloadUrl ? (
          <GeneratedImage src={downloadUrl} />
        ) : (
          <ImageFrame
            info={this.grabInfo()}
            enabled={position === 1}
            updateAR={this.handleUpdate('ar')}
            updateOffset={this.handleUpdate('offset')}
          />
        )}

        <Carousel position={position}>
          <CarouselPane label={`1. ${copy.instructions[locale][0]}`}>
            <ImageSelector onSelect={this.handleUpdate('src')} imageLoaded={!!src} />
          </CarouselPane>

          <CarouselPane label={`2. ${copy.instructions[locale][1]}`}>
            <Slider onChange={this.handleUpdate('scale')} range={[1, 2]} x={scale - 1} />
          </CarouselPane>

          <CarouselPane label={`3. ${copy.instructions[locale][2]}`}>
            <BackgroundSelector onSelect={this.handleUpdate('background')} selected={background} />
          </CarouselPane>

          <CarouselPane label={`4. ${copy.instructions[locale][3]}`}>
            <TextInput onChange={this.handleUpdate('text1')} text={text1} i={0} />
          </CarouselPane>

          <CarouselPane label={`5. ${copy.instructions[locale][4]}`}>
            <TextInput onChange={this.handleUpdate('text2')} text={text2} i={1} />
          </CarouselPane>

          <CarouselPane label={`6. ${copy.instructions[locale][5]}`}>
            <TextInput onChange={this.handleUpdate('text3')} text={text3} i={2} />
          </CarouselPane>

          <CarouselPane label={`7. ${copy.instructions[locale][6]}`}>
            <Slider onChange={this.handleUpdate('shadow')} range={[0, 1]} x={shadow} />
          </CarouselPane>

          <CarouselPane label={`${copy.instructions[locale][7]}`}>
            <Text>{`${copy.downloadInstruction[locale]}`}</Text>
            <ShareButton onClick={() => this.handleShare(downloadUrl)} disabled={position !== 7}>Share</ShareButton>
            {/* <SaveButton onClick={this.handleDownload}></SaveButton> */}
            {/* <SharingButtons url={downloadUrl}></SharingButtons> */}
          </CarouselPane>
        </Carousel>

        <CSSTransition in={uploading} timeout={200} classNames="upload" unmountOnExit>
          <LoadingScreen label="uploading" />
        </CSSTransition>
      </Container>
    );
  };
}

const mapState = state => ({
  info: state.info,
  locale: state.locale,
});

const mapDispatch = dispatch => ({
  updateInfo: info => dispatch({ type: 'UPDATE_INFO', payload: info }),
});

export default connect(
  mapState,
  mapDispatch
)(WorkBench);
