import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import copy from 'assets/copy.json';
import { CloseIcon, BackIcon, NextIcon, RestartIcon, SaveIcon, ShareIcon } from 'assets/icons';

const Container = styled.div`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  align-items: center;
`;

const Label = styled.p`
  color: ${({ disabled }) => (disabled ? 'lightgrey' : 'black')};
  font-size: 4.3vw;
  margin: 0 10px;
  padding: 0;
`;

const SpecialButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  background-image: linear-gradient(90deg, #FADEA1, #FBB38C);
  border: none;
  color: white;
  font-size: 4vw;
  font-weight: 600;
  height: 12vw;
  outline: none;
  padding: 0;
  width: 60vw;
`;

const mapState = state => ({
  locale: state.locale,
});

const Button = connect(mapState)(({ disabled, hide, Icon, label, locale, onClick, reverse }) => (
  <Container onClick={disabled ? null : onClick} hide={hide} reverse={reverse}>
    <Label disabled={disabled}>{copy[label][locale]}</Label>
    <Icon disabled={disabled} />
  </Container>
));

const SpecialButtons = connect(mapState)(({ disabled, hide, Icon, label, locale, onClick, reverse }) => (
  <SpecialButtonContainer onClick={disabled ? null : onClick} hide={hide} reverse={reverse}>
    <Label disabled={disabled}>{copy[label][locale]}</Label>
    <Icon disabled={disabled} />
  </SpecialButtonContainer>
));

Button.defaultProps = {
  reverse: false,
};

Button.propTypes = {
  Icon: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  reverse: PropTypes.bool,
};

export const BackButton = props => <Button Icon={BackIcon} label="back" {...props} reverse />;

export const NextButton = props => <Button Icon={NextIcon} label="next" {...props} />;

export const FinishButton = props => <Button Icon={NextIcon} label="finish" {...props} />;

export const RestartButton = props => <Button Icon={RestartIcon} label="restart" {...props} />;

export const SaveButton = props => <SpecialButtons Icon={SaveIcon} label="save" {...props} />;

export const ShareButton = props => <SpecialButtons Icon={ShareIcon} label="share" {...props} />;

export const CloseButton = ({ black, onClick }) => (
  <button
    onClick={onClick}
    type="button"
    style={{
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      padding: 0,
      position: 'absolute',
      right: 20,
      top: 20,
    }}
  >
    <CloseIcon onClick={onClick} black={black} />
  </button>
);

CloseButton.defaultProps = {
  black: false,
};

CloseButton.propTypes = {
  black: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

const WhiteButtonBox = styled.button`
  background-color: white;
  border: none;
  font-family: 'Kanit';
  font-size: 4vw;
  font-weight: 600;
  height: 12vw;
  margin: 2vw;
  width: 60%;
  min-width: 150px;
`;

export const WhiteButton = props => {
  const { children } = props;

  return <WhiteButtonBox {...props}>{children}</WhiteButtonBox>;
};

WhiteButton.propTypes = {
  children: PropTypes.string.isRequired,
};
