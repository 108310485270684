import styled from 'styled-components';

const fontSizes = {
  small: '3vw',
  normal: '4vw',
  large: '4.5vw',
};

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  background-color: white;
  overflow-y: scroll;
  padding: 40px;
  -webkit-overflow-scrolling: touch;
`;

export const Title = styled.h1`
  font-size: 6.5vw;
  font-weight: 600;
`;

export const Section = styled.section`
  margin: 1em 0;

  p,
  ol,
  ul {
    font-size: ${({ size = 'normal' }) => fontSizes[size]};
    margin: 0;
    white-space: pre-wrap;
  }

  ol,
  ul {
    padding-left: 1.5em;
  }

  ol.lower-roman {
    list-style-type: lower-roman;
  }
`;
