import PropTypes from 'prop-types';
import React from 'react';

import { lockTouch, unlockTouch } from 'utils/scrollLock';
import {
  Container,
  Frame,
  Image,
  Placeholder,
  Spinner,
  Popup,
  Shadow,
} from './GalleryPostPopup.style';

class GalleryPostPopup extends React.PureComponent {
  state = {
    loaded: false,
  };

  static propTypes = {
    hide: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
  };

  componentWillMount = () => {
    lockTouch();
  };

  componentWillUnmount = () => {
    unlockTouch();
  };

  handleLoad = () => {
    this.setState({ loaded: true });
  };

  handleHide = () => {
    const { hide } = this.props;
    hide();
  };

  render = () => {
    const { src } = this.props;
    const { loaded } = this.state;

    return (
      <Popup visible>
        <Shadow onClick={this.handleHide} />
        <Frame>
          <Container>
            <Image src={src} onLoad={this.handleLoad} />
            <Placeholder src={`${src}?w=20`} hide={loaded} />
            <Spinner hide={loaded} />
          </Container>
        </Frame>
      </Popup>
    );
  };
}

export default GalleryPostPopup;
