import PropTypes from 'prop-types';
import React from 'react';
import Parser from 'html-react-parser';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import copy from 'assets/copy.json';
// import globe from 'assets/globe.svg';
import logo from 'assets/primience_logo.svg';

import { lockScroll, unlockScroll } from 'utils/scrollLock';
import { WhiteButton } from 'components/Buttons';
import { LocaleSelector, ScrollingBackground, TextModal } from 'components';
import {
  Container,
  Contents,
  Description,
  // LinkButton,
  LinkLink,
  // LocaleButton,
  Logo,
  Note,
} from './StartScreen.style';

class StartScreen extends React.PureComponent {
  static propTypes = {
    create: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
  };

  state = {
    show: false,
    showModal: false,
    textModal: '',
  };

  componentDidMount = () => {
    lockScroll();
  };

  componentWillUnmount = () => {
    unlockScroll();
  };

  showLocaleSelector = () => {
    this.setState({ show: true });
  };

  hideLocaleSelector = () => {
    this.setState({ show: false });
  };

  showModal = name => () => {
    this.setState({ showModal: true, textModal: name });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  render = () => {
    const { create, hide, locale } = this.props;
    const { show, showModal, textModal } = this.state;

    return (
      <Container>
        <Contents>
          <Logo src={logo} alt="" />
          <Description>
            {Parser(copy.intro[locale])}
            {/* <LinkButton onClick={this.showModal('about')}>{copy.seeMore[locale]}</LinkButton> */}
            <LinkLink href="https://www.shiseido-professional.com/th_th/news-and-events" target="_blank">{copy.seeMore[locale]}</LinkLink>
          </Description>

          <WhiteButton onClick={create}>{copy.makeYourOwn[locale]}</WhiteButton>
          <WhiteButton onClick={hide}>{copy.seeTheCampaign[locale]}</WhiteButton>

          <Note>
            {Parser(copy.footer[locale])}
          </Note>

          {/* <Note>
            <p>ในการร่วมกิจกรรม คุณได้ยอมรับใน </p>
            <LinkLink href="#">ข้อตกลงและกำหนด</LinkLink> และ 
            <LinkButton key={'privacy'} onClick={this.showModal('privacy')}>นโยบายความเป็นส่วนตัว</LinkButton> ของเราแล้ว 
            {copy.footnote[locale].split('$').map(text => {
              switch (text) {
                case 'privacy':
                  return (
                    <LinkLink
                      key={text}
                      href={`https://www.shiseidogroup.${
                        locale === 'ja_jp' ? 'jp' : 'com'
                      }/privacy-policy/`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {copy[`${text}Label`][locale]}
                    </LinkLink>
                  );

                case 'terms':
                  return (
                    <LinkButton key={text} onClick={this.showModal(text)}>
                      {copy[`${text}Label`][locale]}
                    </LinkButton>
                  );

                default:
                  return <span key={text.slice(0, 10)}>{text}</span>;
              }
            })}
          </Note> */}

          {/* <LocaleButton onClick={this.showLocaleSelector}>
            <img src={globe} alt="" />
          </LocaleButton> */}

          <CSSTransition in={show} timeout={200} classNames="modal" unmountOnExit>
            <LocaleSelector hide={this.hideLocaleSelector} />
          </CSSTransition>

          <CSSTransition in={showModal} timeout={200} classNames="modal" unmountOnExit>
            <TextModal contentType={textModal} hide={this.hideModal} />
          </CSSTransition>
        </Contents>

        <ScrollingBackground />
      </Container>
    );
  };
}

const mapState = state => ({
  locale: state.locale,
});

export default connect(mapState)(StartScreen);
