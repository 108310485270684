import PropTypes from 'prop-types';
import React from 'react';

import { Container, Track } from './Carousel.style';

const Carousel = ({ children, position }) => (
  <Container>
    <Track count={children.length} position={position}>
      {children}
    </Track>
  </Container>
);

Carousel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  position: PropTypes.number.isRequired,
};

export default Carousel;
