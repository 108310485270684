import React from 'react';
import { connect } from 'react-redux';

import { Title, Section } from './TextModal.style';

const Terms = ({ locale }) => {
  switch (locale) {
    case 'en':
      return (
        <React.Fragment>
          <Title>Terms of Use</Title>

          <Section size="small">
            <p>
              <strong>Article 1</strong> (Applicability of the Terms of Use)
            </p>
            <p>
              “COLORMIXOLOGY TOOLS” is a service operated by Shiseido Company, Limited (hereinafter,
              “Shiseido”) on the COLORMIXOLOGY campaign website (hereinafter, “the Website”) that
              allows Users to process facial images taken by Users themselves or taken and uploaded
              by Users themselves (only original and unpublished images; hereinafter, “the Images”)
              into advertising-style photographs (hereinafter, “the Service”). These Terms of Use
              establish basic details relevant to using the Service, and they shall apply to all
              Users using the Service.
            </p>
            <p>
              Images produced from the Images provided to and processed by Shiseido will be used by
              Shiseido as described in Article 5, so please obtain prior consent from the subjects
              of the facial images to be provided to Shiseido. Any problems arising in connection
              with the subjects in question shall be resolved on the responsibility and at the
              expense of the User, and Shiseido shall assume no responsibility whatsoever in such
              matters.
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>Article 2</strong> (Consent to the Terms of Use)
            </p>
            <p>
              The User shall comply with these Terms of Use when using the Service and, by actually
              using the Service, shall be deemed to have consented to these Terms of Use. Minors
              should only use the Service with the consent of a parent or guardian, and minors using
              the Service shall be deemed to have obtained the consent of their parents/guardians.
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>Article 3</strong> (Liability for Use of Service)
            </p>
            <ol>
              <li>
                Shiseido makes no guarantee of the completeness, utility, etc., of the information
                disseminated by other Users or obtained by Users through the Service. Users shall
                use the Service on their own responsibility and judgment, and Shiseido shall assume
                no responsibility whatsoever for the consequences of Users using or trusting said
                information.
              </li>
              <li>
                The Service is provided for use in Australia, China, Hong Kong, Indonesia, Japan,
                South Korea, Malaysia, Singapore, Taiwan, and Thailand. Users shall themselves be
                responsible for checking their telecommunications environments and the service
                content of their telecommunications carriers.
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>Article 4</strong> (Handing of Personal Information)
            </p>
            <ul>
              <li>
                All personal information received from Users shall be kept by Shiseido in Japan for
                use in posting to the Website, official SNS accounts, digital social media, printed
                advertising, digital advertising, television, and the Internet, and shall not be
                used for any other purpose. Shiseido shall not provide a User’s personal information
                to a third party without the consent of the User. Shiseido shall be responsible for
                suitably disposing of personal information received from a User two years from the
                date on which the User finished processing the Images.
              </li>
              <li>
                Requests regarding notification of the purposes of use of the User’s personal
                information, disclosure, revision, addition, or deletion of personal information, or
                termination of its use and complete erasure of the information as well as the right
                to refuse provision of the information to a third party shall, in the absence of a
                specific reason to the contrary, be suitably accommodated by Shiseido.
              </li>
              <li>
                Shiseido shall appoint the manager of the organization responsible for information
                security to serve as its Personal Information Protection Officer to protect personal
                information.
              </li>
              <li>
                Inquiries regarding personal information should be directed to the following
                address:
                <br />
                {'Inquiries: '}
                <a
                  href="https://www.shiseidogroup.com/inquiry/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.shiseidogroup.com/inquiry/
                </a>
              </li>
              <li>
                Notwithstanding the above provisions, Shiseido shall handle the User’s personal
                information received from the User in accordance with “
                <a
                  href="https://www.shiseidogroup.com/privacy-policy/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Notice
                </a>
                ”.
              </li>
            </ul>
          </Section>

          <Section size="small">
            <p>
              <strong>Article 5</strong> (Posting on the Website, etc.)
            </p>
            <p>
              Shiseido may post images produced by processing the Images for a period of two years
              after the User has finished processing the Images, with no geographical limitations,
              on:
            </p>
            <ul>
              <li>the Website</li>
              <li>Shiseido’s official SNS accounts</li>
              <li>digital social media</li>
              <li>printed advertising</li>
              <li>digital advertising</li>
              <li>television</li>
            </ul>
            <p>and elsewhere on the Internet.</p>
            <p>
              If any of the Images or the personal information provided to Shiseido (inclusive of
              the Images) are transferred to countries/regions other than Japan, the Images/personal
              information shall be handled in these countries/regions in accordance with these Terms
              of Use and “Personal Information”.
            </p>
            <p>
              The User shall be deemed to have given prior consent to the images produced by
              processing the Images being downloaded by third parties for secondary use from the
              aforementioned media on which they are posted.
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>Article 6</strong> (Copyrights and Other Intellectual Property Rights)
            </p>
            <ol>
              <li>
                All rights relating to content posted on the Website and Shiseido’s official SNS
                accounts belong to or are being exercised by Shiseido under license or other
                legitimate title, and none of the content posted on the Website or Shiseido’s
                official SNS accounts may be used for any purpose other than personal use, or
                redelivered to the public, or used or reproduced by the Users without permission,
                whether inside or outside the network.
              </li>
              <li>
                All trademarks and service marks appearing on the Website or official SNS accounts
                are owned by Shiseido or used under license or other legitimate right, and Users are
                prohibited from using these trademarks and service marks without permission.
              </li>
              <li>
                Notwithstanding 1. above, all copyrights pertaining to the Images and to images
                produced by processing the Images shall belong to the Users who provided the Images
                or the images produced by processing the Images to Shiseido, but the Users shall
                permit Shiseido to use the Images and the images produced by processing the Images
                as described in the preceding Article without any geographical limitations for a
                period of two years after the User finishes processing the Images, and the Users
                shall not exercise the moral rights of authors against Shiseido.
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>Article 7</strong> (Prohibitions) Users are prohibited by Shiseido from
              engaging in any of the acts described below (including acts or preparatory acts that
              might trigger the acts described below):
            </p>
            <ol>
              <li>
                Any act that libels, slanders, or defames, or that might libel, slander, or defame
                other Users, third parties, and/or Shiseido;
              </li>
              <li>
                Any act that infringes or might infringe the property rights or privacy of other
                Users, third parties, and/or Shiseido;
              </li>
              <li>
                Any act that infringes or might infringe the intellectual property rights
                (copyrights, design rights, patent rights, utility model rights, trademark rights,
                and portrait rights) of other Users, third parties, and/or Shiseido;
              </li>
              <li>
                Any act that infringes or might infringe the rights of other Users, third parties,
                and/or Shiseido;
              </li>
              <li>Any act to transmit or write in harmful computer programs;</li>
              <li>
                Any act that offends or might offend public order and morals or that constitutes or
                might constitute criminal behavior;
              </li>
              <li>Any act to use or to be prepared to use the Service for commercial purposes;</li>
              <li>
                An election campaign or any similar act, or any other act that relates to politics
                and/or religion;
              </li>
              <li>Any other act that hinders operation of the Service;</li>
              <li>
                Any act that violates the laws/ordinances of Japan and/or those of the
                country/region in which the User is present while using the Service.
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>Article 8</strong> (Modifications to these Terms of Use)
            </p>
            <p>
              Shiseido reserves the right to modify all or part of these Terms of Use at its sole
              discretion without prior notice to Users. In such instances, Shiseido shall give
              notice of such modifications on its Website, and these modifications shall take effect
              at the time of such notice on the Website.
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>Article 9</strong> (Changes, Additions, and Termination of the Service)
            </p>
            <ol>
              <li>
                In any of the following events, Shiseido may temporarily suspend or terminate
                provision of all or part of the Service without giving prior notice to Users:
                <ol className="lower-roman">
                  <li>
                    In the event of regular or urgent maintenance, inspection, etc. of the systems
                    that provide the Service;
                  </li>
                  <li>In the event of a failure of the systems that provide the Service;</li>
                  <li>
                    In the event of a power outage, fire, earthquake, or any other force majeure
                    that makes provision of the Service difficult;
                  </li>
                  <li>
                    In the event that there are other reasonable grounds pertaining to the operation
                    or technology of the Service.
                  </li>
                </ol>
              </li>
              <li>
                Shiseido may make a change or addition to or terminate all or part of the Service
                without prior notice to Users.
              </li>
              <li>
                Shiseido shall not be held liable for any detriment or damage incurred by the Users
                as a result of any of the preceding actions.
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>Article 10</strong> (Compensation for Damages)
            </p>
            <p>
              If any User violates these Terms of Use or inflicts damage on Shiseido by fraudulent
              acts, Shiseido may claim reasonable compensation from the User. Any Users who, by
              using the Services, inflict damage on third parties shall settle such damage on their
              own responsibility.
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>Article 11</strong> (Indemnity)
            </p>
            <ol>
              <li>
                Shiseido shall not be held responsible to the Users or third parties for any
                interruption, slowdown, or suspension of the systems that provide the Service or
                data loss stemming therefrom of for any unauthorized access of data due to a failure
                in telecommunication lines, computers, etc.
              </li>
              <li>
                Shiseido offers no guarantee that e-mail content sent from the Website and other
                Company web pages, servers, domain, etc. is free of computer viruses or other
                harmful elements.
              </li>
              <li>
                Shiseido shall not be held responsible for any trouble or disputes that arise
                between Users of the Website or between a User and a third party over the Website or
                SNS posts regarding the Website.
              </li>
              <li>
                Shiseido shall not be held responsible for any harm caused by interruption or
                termination of Website operation stemming from the operating environment of an SNS
                or related application.
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>Article 12</strong> (Governing Law, Court with Jurisdiction)
            </p>
            <p>
              All interpretations and applications of the Website and these Terms of Use shall be
              governed by the laws of Japan. Unless otherwise provided, any and all disputes arising
              from or in connection with the Website shall be subject to the exclusive jurisdiction
              of the Tokyo District Court as the agreed court of first instance.
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>Article 13</strong> (Inquiries)
            </p>
            <p>
              Inquiries regarding the Service should be directed to Shiseido Professional’s official
              website (
              <a
                href="https://www.shiseido-professional.com/en"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.shiseido-professional.com/en
              </a>
              ).
            </p>
          </Section>

          <Section size="small">
            <p>Initial version established on February 15, 2019</p>
          </Section>
        </React.Fragment>
      );

    case 'ja_jp':
      return (
        <React.Fragment>
          <Title>利用規約</Title>

          <Section size="small">
            <p>
              <strong>第1条</strong>（利用規約の適用）
            </p>
            <p>
              「COLORMIXOLOGY
              TOOLS」は、株式会社資生堂（以下、「当社」という）がCOLORMIXOLOGYキャンペーンサイト（
              以下、「本ウェブサイト」という）上で運営する、お客様ご自身が撮影した顔画像もしくはお
              客様ご自身が撮影しアップロードされた顔画像（オリジナルのものに限ります。以下、あわせ
              て「本画像」という）を、お客様ご自身で広告風の写真へ加工処理を行っていただけるサービ
              ス（以下、「本サービス」という）です。
              本規約は、本サービスの利用に際し、基本的な事項を定めており、本サービスを利用する全て
              の利用者に適用されるものとします。
            </p>
            <p>
              本画像については当社に提供され、本画像を加工処理した画像については当社が第5条に記載の
              とおり利用しますので、この点について、予め当該顔画像のご本人の同意を得てください。当
              該ご本人との間で何らかのトラブルが発生した場合には、利用者の責任と費用をもってこれを
              解決するものとし、当社は一切の責任を負いません。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第2条</strong>（利用規約の同意）
            </p>
            <p>
              利用者は、本サービスを利用するにあたり、本規約に従うものとします。利用者が本サービス
              を実際に利用した場合、本規約に同意したものとみなします。
            </p>
            <p>
              また、未成年の方は保護者の同意を得た上で本サービスを利用ください。未成年の方が本サー
              ビスを利用された場合は、保護者の同意を得ているものとみなします。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第3条</strong>（本サービスの利用責任）
            </p>
            <ol>
              <li>
                当社は、他の利用者が発信する情報等、利用者が本サービスを通じて得る情報について、そ
                の完全性、有用性等を保証するものではありません。また、利用者は利用者自身の責任にお
                いて判断し、本サービスを利用するものとし、利用者がこれらの情報等を利用または信用す
                ることによる結果について、当社は一切の責任を負わないものとします。
              </li>
              <li>
                本サービスは、中国・香港・日本・韓国・台湾・シンガポール・マレーシア・タイ・インド
                ネシアの国と地域での利用を前提として提供しています。利用者は、利用者の責任で通信環
                境や各通信キャリア（電気通信業者）のサービス内容を確認するものとします。
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第4条</strong>（個人情報の取扱い）
            </p>
            <ul>
              <li>
                利用者からいただいた全ての個人情報は、日本に所在する当社がお預かりし、本ウェブサイ
                ト、公式SNSアカウント、デジタルソーシャルメディア、紙媒体広告、デジタル広告、テレビ
                、ウェブへの掲載にのみ使用し、その他の目的では使用いたしません。また、当社は、個人
                情報を利用者の同意なく第三者に提供いたしません。利用者からいただいた個人情報は、利
                用者が本画像の加工処理を完了した日の2年後に、当社にて責任をもって廃棄いたします。
              </li>
              <li>
                利用者の個人情報等の利用目的の通知、個人情報の開示、訂正、追加または削除および利用
                停止、消去または第三者への提供の拒否権に関するご請求は、特別な理由がない限り適切に
                対応いたします。
              </li>
              <li>
                当社は、情報セキュリティを担当する部門の責任者を個人情報保護管理者とし、個人情報の
                保護を行っています。
              </li>
              <li>
                個人情報に関するお問い合わせは、下記までお願いいたします。
                <br />
                お問い合わせ先：
                <a
                  href="http://www.shiseidogroup.jp/inquiry/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  http://www.shiseidogroup.jp/inquiry/
                </a>
              </li>
              <li>
                上記の定めにかかわらず、当社は、利用者からいただいた利用者の個人情報を「
                <a
                  href="https://www.shiseidogroup.jp/privacy-policy/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  個人情報について
                </a>
                」に基づいて取り扱います。
              </li>
            </ul>
          </Section>

          <Section size="small">
            <p>
              <strong>第5条</strong>（本ウェブサイト等への掲載）
            </p>
            <p>
              当社は、本画像を加工処理した画像を、利用者が本画像の加工処理完了後2年間、地域に制限なく、
            </p>
            <ul>
              <li>本ウェブサイト</li>
              <li>公式SNSアカウント</li>
              <li>デジタルソーシャルメディア</li>
              <li>紙媒体広告</li>
              <li>デジタル広告</li>
              <li>テレビ</li>
              <li>ウェブ</li>
            </ul>
            <p>
              に掲載することができるものとします。当社に提供された本画像及び本画像を含む全ての個人
              情報が日本以外の国または地域に移転される場合には、当該国又は地域においても本規約及び
              「個人情報について」に基づいて適切に取り扱います。
            </p>
            <p>
              なお、本画像を加工処理した画像は、掲載された上記の各媒体により第三者によってダウンロ
              ードや二次利用される場合があることを、利用者は予め承諾するものとします。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第6条</strong>（著作権その他知的財産権）
            </p>
            <ol>
              <li>
                本ウェブサイト、および、公式SNSに掲載された全ての内容に関する権利は当社に帰属するか
                または当社がライセンスその他正当な権原にもとづき使用するものであり、利用者が本ウェ
                ブサイトおよび、公式SNSの掲載内容を、自らの個人利用目的以外に利用したり、ネットワー
                クの内外を問わず、公衆に再提供したり、無断使用・複製等することを禁止します。
              </li>
              <li>
                本ウェブサイトおよび、公式SNSに現れるすべての商標およびサービスマークは、当社が所有
                し、またはライセンスその他の正当な権原にもとづき使用するものであり、当社は利用者が
                これらを無断で使用することを禁止します。
              </li>
              <li>
                第1項にかかわらず、本画像および本画像を加工処理した画像���関わる著作権は、当社に本画
                像および本画像を加工処理した画像を提供された利用者に帰属しますが、利用者は、当社が
                、利用者が本画像の加工処理完了後2年間、地域に制限なく本画像および本画像を加工処理し
                た画像を前条のとおり利用することを許諾し、当社に対して著作者人格権を行使しません。
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第7条</strong>（禁止事項）
            </p>
            <p>
              当社は、利用者が、本サービスを利用するにあたり、以下に定める行為（それらを誘発する行
              為や準備行為を含む）を行うことを禁止します。
            </p>
            <ol>
              <li>
                他の利用者および第三者ならびに当社を誹謗または中傷したり名誉を毀損する行為、または
                それらのおそれのある行為
              </li>
              <li>
                他の利用者および第三者もしくは当社の財産権やプライバシーを侵害する行為、または侵害
                するおそれのある行為
              </li>
              <li>
                他の利用者および第三者もしくは当社の知的財産権（著作権・意匠権・特許権・実用新案権
                ・商標権・肖像権）を侵害する行為、または侵害するおそれのある行為
              </li>
              <li>
                他の利用者および第三者もしくは当社の権利を侵害する行為、または侵害するおそれのある行為
              </li>
              <li>有害なコンピュータープログラム等を送信、または書き込む行為</li>
              <li>公序良俗に反する行為、犯罪行為、またはそのおそれのある行為</li>
              <li>本サービスを営利目的で利用する行為、またはその準備を目的とする行為</li>
              <li>選挙活動、またはこれに類する行為、その他政治および宗教に関する行為</li>
              <li>その他本サービスの運営を妨げるような行為</li>
              <li>日本国およびご利用の際に利用者が所在する国・地域の法令に違反する行為</li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第8条</strong>（規約の改定）
            </p>
            <p>
              当社は、本規約の内容の全部または一部を利用者に事前に通知することなく任意に改定することができるものとします。この場合、当社は本ウェブサイト上で改定を通知するものとし、改定の効力は本ウェブサイトへの掲示時点で生ずるものとします。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第9条</strong>（サービスの変更、追加、終了）
            </p>
            <ol>
              <li>
                当社は、次のいずれかの事由が生じた場合、利用者に対して事前に通知することなく、本サービスの全部または一部の提供を一時停止しまたは終了できるものとします。
                <ol className="lower-roman">
                  <li>
                    本サービスを提供するシステムの保守点検等の作業を定期的にまたは緊急に行う場合
                  </li>
                  <li>本サービスを提供するシステムに故障等が生じた場合</li>
                  <li>停電、火災、地震その他不可抗力により本サービスの提供が困難な場合</li>
                  <li>その他、本サービスの運用上または技術上の相当な理由がある場合</li>
                </ol>
              </li>
              <li>
                当社は、本サービスの内容の全部または一部について、変更、追加、終了を、事前に通知することなく行うことができるものとします。
              </li>
              <li>
                前各項の措置により、利用者に不利益、損害が発生したとしても、当社は一切の責任を負わないものとします。
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第10条</strong>（損害賠償）
            </p>
            <p>
              利用者が本規約に違反し、または不正行為により当社に対し損害を与えた場合、当社は利用者に対し相応の損害賠償請求ができるものとします。また、利用者が本サービスの利用により第三者に対し損害を与えた場合、利用者は自己の責任でこれを解決するものとします
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第11条</strong>（免責）
            </p>
            <ol>
              <li>
                通信回線やコンピューターなどの障害によるシステムの中断・遅滞・中止・データの消失、データへの不正アクセスにより利用者に生じた損害について、当社は一切責任を負わないものとします。
              </li>
              <li>
                当社は、本サイトを含む当社のWebページ・サーバー・ドメインなどから送られるメール・コンテンツに、コンピュータウイルスなどの有害なものが含まれていないことを保証いたしません。
              </li>
              <li>
                当社は、本サイトについてのSNSへの投稿を含め、本サイトの利用者間、もしくは利用者と第三者間でトラブル、紛争が生じた場合であっても、一切責任を負いません。
              </li>
              <li>
                各SNSおよび関連するアプリケーションの動作環境により発生する本サイト運営の中断または中止によって生じるいかなる損害についても、当社が責任を負うものではありません。
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第12条</strong>（準拠法・管轄裁判所）
            </p>
            <p>
              本ウェブサイトならびに本規約の解釈および適用は、日本国法に準拠します。また、本ウェブサイトに関わる全ての紛争については、他に別段の定めのない限り、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第13条</strong>（お問い合わせ）
            </p>
            <p>
              本サービスに関するお問い合わせは、資生堂プロフェッショナル公式ウェブサイト（
              <a
                href="https://www.shiseido-professional.com/ja_jp"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.shiseido-professional.com/ja_jp
              </a>
              ）をご確認ください。
            </p>
          </Section>

          <Section size="small">
            <p>2019年2月15日初版制定</p>
          </Section>
        </React.Fragment>
      );

    case 'ko_kr':
      return (
        <React.Fragment>
          <Title>이용 규약</Title>

          <Section size="small">
            <p>
              <strong>제1조</strong>（이용 규약의 적용）
            </p>
            <p>
              ‘COLORMIXOLOGY　TOOLS’는 주식회사 시세이도(이하 ‘당사’라고 함)가 COLORMIXOLOGY캠페인
              사이트(이하 ‘본 웹사이트’라고 함)상에서 운영하는 이용자 자신이 촬영한 얼굴 영상 또는
              이용자 자신이 촬영하여 업로드한 얼굴 영상(오리지널로 미공표 한 것에 한합니다. 이하,
              모두 ‘본 영상’이라고 함)을 이용자 자신이 광고풍의 사진으로 가공처리를 하실 수 있는
              서비스(이하 ‘본 서비스’라고 함)입니다. 본 규약은 본 서비스 이용 시의 기본적인 사항을
              규정하고 있으며 본 서비스를 이용하는 모든 이용자에게 적용되는 것으로 합니다.
            </p>
            <p>
              본 영상에 대해서는 당사에게 제공되며 본 영상을 가공처리한 영상에 대해서는 당사가
              제5조에 기재한 대로 이용할 것이므로 이 점에 대해서 미리 해당 얼굴 영상인 본인의 동의를
              받으십시오. 해당 본인과의 사이에서 무언가 문제가 발생한 경우에는 이용자의 책임과
              비용으로 이를 해결하는 것으로 하며 당사는 일체의 책임을 지지 않습니다.
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>제2조</strong>（이용 규약의 동의）
            </p>
            <p>
              이용자는 본 서비스를 이용하는데 있어서 본 규약에 따르는 것으로 합니다. 이용자가 본
              서비스를 실제로 이용한 경우, 본 규약에 동의한 것으로 간주합니다.
            </p>
            <p>
              또한 미성년인 분은 보호자의 동의를 얻은 후 본 서비스를 이용하십시오. 미성년인 분이 본
              서비스를 이용하신 경우에는 보호자의 동의를 얻은 것으로 간주합니다.
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>제3조</strong>（본 서비스 이용 책임）
            </p>
            <ol>
              <li>
                당사는 다른 이용자가 내보내는 정보 등 이용자가 본 서비스를 통해서 얻는 정보에 대해서
                그 완전성, 유용성 등을 보장하는 것은 아닙니다. 또한 이용자는 이용자 자신의 책임
                하에서 판단하여 본 서비스를 이용하는 것으로 하며 이용자가 이러한 정보 등을 이용 또는
                신용하는 것으로 인한 결과에 대해서는 당사는 일체의 책임을 지지 않는 것으로 합니다
              </li>
              <li>
                본 서비스는 호주・중국・홍콩・인도네시아・일본・한국・말레이시아・싱가폴・대만・태국
                등 국가와 지역에서의 이용을 전제로 하여 제공하고 있습니다. 이용자는 이용자의
                책임으로 통신 환경 및 각 통신사(전기통신업체)의 서비스 내용을 확인하는 것으로
                합니다.
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>제4조</strong>（개인정보의 취급）
            </p>
            <ul>
              <li>
                이용자로부터 받은 모든 개인정보는 일본에 소재하는 당사가 맡아서 본 웹사이트, 공식
                SNS계정, 디지털 소셜미디어, 종이 매체 광고, 디지털 광고, TV, 웹 게재에만 사용하며
                다른 목적으로는 사용하지 않습니다. 또한 당사는 개인정보를 이용자의 동의 없이
                제3자에게 제공하지 않습니다. 이용자로부터 받은 개인정보는 이용자가 본 영상의
                가공처리를 완료한 날의 2년 후에 당사에서 책임을 지고 폐기할 것입니다.
              </li>
              <li>
                이용자의 개인정보 등의 이용목적의 알림, 개인정보 공개, 정정, 추가 또는 삭제 및
                이용정지, 소거 또는 제3자에 대한 제공 거부권에 관한 청구는 특별한 이유가 없는 한
                적절하게 대응할 것입니다.
              </li>
              <li>
                당사는 정보 보안을 담당하는 부문의 책임자를 개인정보보호관리자라고 하며 개인정보
                보호를 실시하고 있습니다.
              </li>
              <li>
                개인정보에 관한 문의는 아래로 부탁드립니다.
                <br />
                문의처：
                <a
                  href="https://www.shiseidogroup.com/inquiry/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.shiseidogroup.com/inquiry/
                </a>
              </li>
              <li>
                상기의 규정에도 불구하고 당사는 이용자로부터 받은 이용자의 개인정보를 ‘
                <a
                  href="https://www.shiseidogroup.com/privacy-policy/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  개인정보에 대하여
                </a>
                ’ 에 의거하여 취급합니다.
              </li>
            </ul>
          </Section>

          <Section size="small">
            <p>
              <strong>제5조</strong>（본 웹사이트 등에 게재）
            </p>
            <p>
              당사는 본 영상을 가공처리한 영상을 이용자가 본 영상의 가공처리 완료 후 2년간 지역에
              제한없이
            </p>
            <ul>
              <li>본 웹사이트</li>
              <li>공식 SNS계정</li>
              <li>디지털 소셜미디어</li>
              <li>종이 매체 광고</li>
              <li>디지털 광고</li>
              <li>TV</li>
              <li>웹</li>
            </ul>
            <p>
              에 게재할 수 있는 것으로 합니다. 당사에 제공된 본 영상 및 본 영상을 포함하는 모든
              개인정보가 일본 이외의 국가 또는 지역으로 이전되는 경우에는 해당 국가 또는 지역에
              있어서도 본 규약 및 ‘개인정보에 대하여’에 의거하여 적절하게 취급합니다.
            </p>
            <p>
              또한 본 영상을 가공처리한 영상은, 게재된 상기의 각 매체를 통해 제3자에 의해 다운로드
              및 2차 이용되는 경우가 있다는 사실을 이용자가 미리 승낙하는 것으로 합니다.
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>제6조</strong>（저작권 기타 지적재산권）
            </p>
            <ol>
              <li>
                본 웹사이트 및 공식 SNS에 게재된 모든 내용에 관한 권리는 당사에 귀속되거나 또는
                당사가 라이선스 기타 정당한 권원에 의거하여 사용하는 것이며 이용자가 본 웹사이트 및
                공식 SNS의 게재 내용을 자신의 개인 이용 목적 이외에 이용하거나 네트워크 안팎을
                불문하고 공중에게 재제공 하거나 무단사용・복제 등을 하는 것을 금지합니다.
              </li>
              <li>
                본 웹사이트 및 공식 SNS에 나타나는 모든 상표 및 서비스마크는 당사가 소유하고 또는
                라이선스 기타 정당한 권원에 의거하여 사용하는 것이며 당사는 이용자가 이를 무단으로
                사용하는 것을 금지합니다.
              </li>
              <li>
                제1항에도 불구하고 본 영상 및 본 영상을 가공처리한 영상 관련 저작권은 당사에게 본
                영상 및 본 영상을 가공처리한 영상을 제공한 이용자에게 귀속됩니다만 이용자는 당사가
                이용자가 본 영상의 가공처리 완료 후 2년간 지역에 제한없이 본 영상 및 본 영상을
                가공처리한 영상을 전조(前條) 대로 이용하는 것을 허락하여 당사에 대해 저작자인격권을
                행사하지 않습니다.
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>제7조</strong>（금지 사항）
            </p>
            <p>
              당사는 이용자가 본 서비스를 이용하는데 있어서 아래에 규정하는 행위(그러한 것들을
              유발하는 행위나 준비행위를 포함함)를 하는 것을 금지합니다.
            </p>
            <ol>
              <li>
                다른 이용자와 제3자 및 당사를 비방 또는 중상하거나 명예를 훼손하는 행위 또는 그러한
                우려가 있는 행위
              </li>
              <li>
                다른 이용자 및 제3자 또는 당사의 재산권 및 프라이버시를 침해하는 행위 또는 침해할
                우려가 있는 행위
              </li>
              <li>
                다른 이용자 및 제3자 또는 당사의 지적재산권(저작권・의장권・특허권・실용신안권・
                상표권・초상권)을 침해하는 행위 또는 침해할 우려가 있는 행위
              </li>
              <li>
                다른 이용자 및 제3자 또는 당사의 권리를 침해하는 행위 또는 침해할 우려가 있는 행위
              </li>
              <li>유해한 컴퓨터 프로그램 등을 송신 또는 글을 올리는 행위</li>
              <li>공서양속에 반하는 행위, 범죄행위 또는 그 우려가 있는 행위</li>
              <li>본 서비스를 영리 목적으로 이용하는 행위 또는 그 준비를 목적으로 하는 행위</li>
              <li>선거활동 또는 이와 비슷한 행위, 기타 정치 및 종교에 관한 행위</li>
              <li>기타 본 서비스 운영을 방해하는 것 같은 행위</li>
              <li>일본국 및 이용 시에 이용자가 소재하는 국가・지역의 법령에 위반하는 행위</li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>제8조</strong>（규약 개정）
            </p>
            <p>
              당사는 본 규약의 내용 전부 또는 일부를 이용자에게 사전에 알리지 않고 임의로 개정할 수
              있는 것으로 합니다. 이 경우 당사는 본 웹사이트상에서 개정을 알리는 것으로 하며 개정의
              효력은 본 웹사이트에 게시하는 시점에 발생하는 것으로 합니다.
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>제9조</strong>（서비스 변경, 추가, 종료）
            </p>
            <ol>
              <li>
                당사는 다음의 그 어떤 사유가 발생한 경우, 이용자에 대해 사전에 알리지 않고 본
                서비스의 전부 또는 일부 제공을 일시정지 또는 종료할 수 있는 것으로 합니다.
                <ol className="lower-roman">
                  <li>
                    본 서비스를 제공하는 시스템의 보수 점검 등의 작업을 정기적 또는 긴급하게 실시할
                    경우
                  </li>
                  <li>본 서비스를 제공하는 시스템에 고장 등이 발생했을 경우</li>
                  <li>정전, 화재, 지진 기타 불가항력으로 인해 본 서비스의 제공이 어려운 경우 </li>
                  <li>기타 본 서비스의 운용상 또는 기술상의 상당한 이유가 있는 경우</li>
                </ol>
              </li>
              <li>
                당사는 본 서비스 내용의 전부 또는 일부에 대해서 변경, 추가, 종료를 사전에 알리지
                않고 실시할 수 있는 것으로 합니다.
              </li>
              <li>
                전 각항(前各項)의 조치에 의해 이용자에게 불이익, 손해가 발생했다 하더라도 당사는
                일체의 책임을 지지 않는 것으로 합니다.
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>제10조</strong>（손해배상）
            </p>
            <p>
              이용자가 본 규약에 위반하고 또는 부정행위로 인해 당사에 대해 손해를 끼친 경우, 당사는
              이용자에 대해 상응하는 손해배상 청구를 할 수 있는 것으로 합니다. 또한 이용자가 본
              서비스 이용으로 인해 제3자에 대해 손해를 끼친 경우 이용자는 자기 책임으로 이를
              해결하는 것으로 합니다.
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>제11조</strong>（면책）
            </p>
            <ol>
              <li>
                통신 회선 및 컴퓨터 등의 장애로 인한 시스템 중단・지체・중지・데이터 소실, 데이터에
                부정 접속으로 인해 이용자에게 발생한 손해에 대해 당사는 일체 책임을 지지 않는 것으로
                합니다.
              </li>
              <li>
                당사는 본 사이트를 포함한 당사의 Web페이지・서버・도메인 등에서 보내오는 메일
                ・콘텐츠에 컴퓨터 바이러스 등의 유해한 것이 포함되어 있지 않다는 것을 보장하지
                않습니다.
              </li>
              <li>
                당사는 본 사이트에 대한 SNS게재를 포함하여 본 사이트 이용자 간 또는 이용자와 제3자
                간의 문제, 분쟁이 발생한 경우라 하더라도 일체 책임을 지지 않습니다.
              </li>
              <li>
                각 SNS 및 관련 애플리케이션의 동작 환경으로 인해 발생하는 본 사이트의 운영 중단 또는
                중지로 인해 발생하는 그 어떤 손해에 대해서도 당사가 책임을 지는 것은 아닙니다.
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>제12조</strong>（준거법・관할 법원）
            </p>
            <p>
              본 웹사이트 및 본 규약의 해석 및 적용은 일본국법에 준거합니다. 또한 본 웹사이트와
              관련된 모든 분쟁에 대해서는 따로 별다른 규정이 없는 한 도쿄지방재판소를 제1심 전속적
              합의관할 재판소로 합니다.
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>제13조</strong>（문의）
            </p>
            <p>
              본 서비스에 관한 문의는 시세이도 프로페셔널 공식 웹사이트（
              <a
                href="https://www.shiseido-professional.com/ko_kr"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.shiseido-professional.com/ko_kr
              </a>
              ）확인하시기 바랍니다.
            </p>
          </Section>

          <Section size="small">
            <p>2019년 2월 15일 초판 제정</p>
          </Section>
        </React.Fragment>
      );

    case 'zh_cn':
      return (
        <React.Fragment>
          <Title>使用条款</Title>

          <Section size="small">
            <p>
              <strong>第1条</strong>（使用条款的适用）
            </p>
            <p>
              “COLORMIXOLOGY　TOOLS”是一项服务（以下简称为“本服务”），由株式会社资生堂（以下简称为“
              本公司”）在COLORMIXOLOGY促销活动网站（以下简称为“本网站”）上提供，目的是让用户亲自把
              自己拍摄的面部图像或自己拍摄后上传的面部图像（仅限于原创且未公开发布的面部图像。以下
              统称为“本图像”）加工处理广告式照片。本条款规定了使用本服务时的基本事项，适用于使用本
              服务的所有用户。
            </p>
            <p>
              对于本图像，用户向本公司提供后，由本公司使用；对于加工处理本图像之后的图像，由本公司
              按照第5条的规定使用，对此，请事先得到该面部图像的本人同意。用户与该本人之间产生任何纠
              纷时，都应由用户承担责任和费用加以解决，本公司不承担任何责任。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第2条</strong>（使用条款的同意）
            </p>
            <p>用户使用本服务时，应遵守本条款。用户实际使用本服务时，将视为已同意本条款。</p>
            <p>
              另外，未成年人应在得到家长同意之后使用本服务。未成年人使用了本服务时，将视为已得到家
              长同意。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第3条</strong>（本服务的使用责任）
            </p>
            <ol>
              <li>
                本公司对于其他用户发布的信息等、用户通过本服务获得的信息，不保证其完整性、有用性等
                。此外，用户应自己负责判断后使用本服务，对于用户因使用或相信这些信息等造成的结果，
                本公司不承担任何责任。
              </li>
              <li>
                本服务是以在澳大利亚、中国、香港、印度尼西亚、日本、韩国、马来西亚、新加坡、台湾及
                泰国使用为前提提供的。用户应自己负责确认通信环境、各通信运营商（电气通信企业）的服
                务内容。
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第4条</strong>（个人信息的处理）
            </p>
            <ul>
              <li>
                用户提供的所有个人信息，由位于日本的本公司保管，只用于登载到本网站、SNS官方账户、数
                字社交媒体、平面媒体广告、数字广告、电视、网站，不会用于其他目的。同时，未经用户同
                意，本公司不会向第三方提供个人信息。用户提供的个人信息，用户完成本图像的加工处理之
                日的2年后，将由本公司负责废弃。
              </li>
              <li>
                对于通知个人信息等的使用目的，披露、订正、追加、删除、停止使用、消除个人信息，以及
                拒绝向第三方提供个人信息的权利，用户提出要求时，只要没有特殊理由，本公司都会妥善应
                对。
              </li>
              <li>
                本公司规定由主管信息安全的部门的负责人担任个人信息保护管理者，进行个人信息的保护。
              </li>
              <li>
                关于个人信息的咨询，联系方式如下所示：
                <br />
                联系方式：
                <a
                  href="https://www.shiseidogroup.com/inquiry/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.shiseidogroup.com/inquiry/
                </a>
              </li>
              <li>
                尽管有上述规定，本公司将按照“
                <a
                  href="https://www.shiseidogroup.com/privacy-policy/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  关于个人信息
                </a>
                ”处理用户提供的个人信息。
              </li>
            </ul>
          </Section>

          <Section size="small">
            <p>
              <strong>第5条</strong>（在本网站等上登载）
            </p>
            <p>
              本公司可以在用户完成本图像的加工处理后2年里，不限制地区，在下列平台上登载加工处理本图
              像之后的图像：
            </p>
            <ul>
              <li>本网站</li>
              <li>SNS官方账户</li>
              <li>数字社交媒体</li>
              <li>平面媒体广告</li>
              <li>数字广告</li>
              <li>电视</li>
              <li>网站</li>
            </ul>
            <p>
              给本公司提供的本图像以及包括本图像在内的所有个人信息，被转移到日本以外的国家或地区时
              ，在该国家或地区也应依照本条款及“关于个人信息”妥善处理。
            </p>
            <p>
              另外，用户应事先同意加工处理本图像之后的图像登载于上述各媒体后，有可能被第三者下载或
              二次使用。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第6条</strong>（著作权等知识产权）
            </p>
            <ol>
              <li>
                本网站和官方SNS上登载的所有内容的权利，均归属于本公司或者本公司根据许可证等正当权限
                使用的；对于本网站和官方SNS的登载内容，禁止用户超出自己的个人使用目的之外使用、再次
                提供给网络内外的公众、擅自使用和复制等。
              </li>
              <li>
                本网站和官方SNS上出现的所有的商标和服务标记，均归属于本公司或者本公司根据许可证等正
                当权限使用的，本公司禁止用户擅自使用这些商标和服务标记。
              </li>
              <li>
                尽管有第1款的规定，本图像和加工处理本图像之后的图像的著作权，归属于将本图像和加工处
                理本图像之后的图像提供给本公司的用户，但是用户应允许本公司在用户完成本图像的加工处
                理后2年里，在任何地区都能依照上一条的规定使用本图像和加工处理本图像之后的图像，不对
                本公司行使著作人身权。
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第7条</strong>（禁止事项）
            </p>
            <p>本公司禁止用户在使用本服务时采取下列行为（包括引发下列行为的行为或准备行为）：</p>
            <ol>
              <li>
                诽谤或中伤其他用户、第三方或本公司，或者损毁其名誉的行为，或者有此类危险的行为
              </li>
              <li>侵犯其他用户、第三方或本公司的财产权或隐私权的行为，或者有可能侵犯的行为</li>
              <li>
                侵犯其他用户、第三方或本公司的知识产权（著作权、外观设计专利权、专利权、实用新型权、
                商标权、肖像权）的行为，或者有可能侵犯的行为
              </li>
              <li>侵犯其他用户、第三方或本公司的权利的行为，或者有可能侵犯的行为</li>
              <li>发送或写入有害的计算机程序等行为</li>
              <li>违反公序良俗的行为、犯罪行为，或者有此类危险的行为</li>
              <li>以创收为目的使用本服务的行为，或者以其准备为目的的行为</li>
              <li>选举活动或者与此类似的行为，涉及到政治和宗教有关的行为</li>
              <li>妨碍本服务运营之类的行为</li>
              <li>违反日本和用户使用时所在的国家或地区的法令的行为</li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第8条</strong>（条款的修订）
            </p>
            <p>
              本公司可以不事先通知用户就任意修订本条款的全部或部分内容。届时，本公司将在本网站上通
              知修订，修订过的条款在本网站上登载时生效。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第9条</strong>（服务的变更、追加、结束）
            </p>
            <ol>
              <li>
                产生下列事由之一时，无需事先通知用户，本公司就能暂停或结束提供本服务的全部或部分内
                容：
                <ol className="lower-roman">
                  <li>对提供本服务的系统进行定期或临时的维修检查等作业时</li>
                  <li>提供本服务的系统发生故障等时</li>
                  <li>因为停电、火灾、地震等不可抗力，难以提供本服务时</li>
                  <li>有本服务的运行上或技术上的其他必要理由时</li>
                </ol>
              </li>
              <li>无需事先通知用户，本公司就能变更、追加、结束本服务的全部或部分内容。</li>
              <li>即便是因为以上各款的措施，给用户造成不利、损失，本公司也不承担任何责任。</li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第10条</strong>（损失赔偿）
            </p>
            <p>
              因为用户违反本条款或者采取不正当行为，给本公司造成损失时，本公司可以向用户索取相应的
              损失赔偿。此外，因为用户使用本服务，给第三方造成损失时，用户应自己负责加以解决。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第11条</strong>（免责）
            </p>
            <ol>
              <li>
                因为通信线路、计算机等发生故障导致系统中断、延迟、中止、数据消失、非法访问数据，给
                用户造成损失时，本公司对此不承担任何责任。
              </li>
              <li>
                本公司不保证从包括本网站在内的本公司的网页、服务器、域等发送的电子邮件和内容中没有
                计算机病毒等有害内容。
              </li>
              <li>
                包括向SNS发布的有关本网站的信息在内，即使本网站的用户之间或用户与第三方之间产生纠纷
                、争议，本公司也不承担任何责任。
              </li>
              <li>
                对于本网站运营因各种SNS及相关应用程序的工作环境而中断或中止，由此产生的任何损失，本
                公司不承担责任。
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第12条</strong>（适用法律及管辖法院）
            </p>
            <p>
              本网站与本条款的解释及适用，以日本法律为准。此外，对于涉及到本网站的所有争议，除另有
              规定外，以东京地方法院为一审专属管辖法院。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第13条</strong>（咨询）
            </p>
            <p>
              如对本服务有不明之处，请确认资生堂专业美发官方网站（
              <a
                href="https://www.shiseido-professional.com/zh_cn"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.shiseido-professional.com/zh_cn
              </a>
              ）。
            </p>
          </Section>

          <Section size="small">
            <p>2019年2月15日制定第一版</p>
          </Section>
        </React.Fragment>
      );

    case 'zh_tw':
    case 'zh_hk':
      return (
        <React.Fragment>
          <Title>使用規約</Title>

          <Section size="small">
            <p>
              <strong>第1條</strong>（使用規約之適用）
            </p>
            <p>
              「COLORMIXOLOGY　TOOLS」為株式會社資生堂（以下稱「本公司」）於COLORMIXOLOGY活動網站（
              以下稱「本網站」）營運的服務項目，即用戶自己本身可以將自己拍攝的臉部照片圖像、或是用
              戶自己拍攝並上傳至網站的臉部照片圖像（僅限原始未公開的圖像，兩者以下皆稱為「本圖像」
              ）加工處理成廣告風格照片的服務（以下稱「本服務」）。本規約規定了使用本服務時的基本事
              項，適用於使用本服務所有用戶。
            </p>
            <p>
              本圖像為提供予本公司之圖像，將本圖像進行加工處理後的圖像，本公司將依據第5條所記載對圖
              像進行利用，關於此點，請事先取得該臉部照片圖像本人的同意。與該圖像本人之間發生任何糾
              紛時，用戶本身須自行負責並負擔費用解決糾紛，本公司概不負責。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第2條</strong>（使用規約之同意）
            </p>
            <p>使用本服務的用戶必須遵守本規約之規定。用戶實際使用本服務時，即視為同意本規約。</p>
            <p>
              未成年者須經父母或監護人之同意方可使用本服務。未成年者使用本服務時，視為已取得父母或
              監護人之同意。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第3條</strong>（本服務使用上之責任）
            </p>
            <ol>
              <li>
                對於其他用戶發送的訊息等用戶經由本服務所得之資訊的完整性、可用性，本公司不予保證。
                用戶須在自己本身的責任下進行判斷並使用本服務。用戶因使用這些資訊或因信用這些資訊所
                造成的結果，本公司概不負責。
              </li>
              <li>
                本服務為以在澳大利亞、中國、香港、印度尼西亞、日本、韓國、馬來西亞、新加坡、台灣、
                泰國的國家或地區使用為前提所提供的服務。用戶須在各自的責任下，確認通信環境和各電信
                公司（電信事業營業者）的服務內容。
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第4條</strong>（個人資料的管理與使用）
            </p>
            <ul>
              <li>
                用戶登錄的所有個人資料由位於日本的本公司持有，僅使用於刊載於本網站、官方SNS帳號、數
                位社群媒體、紙面媒體廣告、數位廣告、電視、全球資訊網站之用途，不使用於其他目的。未
                經用戶同意，本公司不會將個人資料提供予第三者。用戶登錄的個人資料在用戶對本圖像完成
                加工處理之日起2年後，由本公司負責將之銷毀。
              </li>
              <li>
                用戶個人資料等的使用目的的通知、個人資料的公開、修正、追加或刪除、以及停止使用、消
                去或向第三者提供資料之拒絕權相關的申請，若無特殊理由，本公司將適當地應對處理。
              </li>
              <li>本公司以負責資訊安全之部門的人員作為個人資訊保護管理者，進行個人資料的保護。</li>
              <li>
                個人資料的相關詢問，請至下列網址。
                <br />
                詢問處：
                <a
                  href="https://www.shiseidogroup.com/inquiry/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://www.shiseidogroup.com/inquiry/
                </a>
              </li>
              <li>
                不論以上規定如何，本公司將依據「
                <a
                  href="https://www.shiseidogroup.com/privacy-policy/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  關於個人資料
                </a>
                」管理與使用用戶所登錄的用戶個人資料。
              </li>
            </ul>
          </Section>

          <Section size="small">
            <p>
              <strong>第5條</strong>（在本網站等的刊載）
            </p>
            <p>
              在用戶對本圖像的加工處理完成後2年期間內，本公司有權將本圖像加工處理後的圖像刊載於以下
              媒體上，且不限區域。
            </p>
            <ul>
              <li>本網站</li>
              <li>官方SNS帳號</li>
              <li>數位社群媒體</li>
              <li>紙面媒體廣告</li>
              <li>數位廣告</li>
              <li>電視</li>
              <li>全球資訊網站</li>
            </ul>
            <p>
              使用戶提供的本圖像以及包含本圖像的所有個人資料轉移至日本以外的國家或地區時，在該轉移
              的國家或地區也依據本規約及「關於個人資料」進行適當的管理與使用。
            </p>
            <p>
              另外，用戶應事先同意本圖像加工處理後的圖像刊載於上列的各媒體後，有可能被第三者下載或
              再次使用。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第6條</strong>（著作權及其他智慧財產權）
            </p>
            <ol>
              <li>
                本網站及官方SNS帳號刊載的所有內容的權利歸屬於本公司或視為本公司依據授權等其他合法權
                源使用之權利，禁止用戶將本網站及官方SNS帳號上刊載的內容使用於自己個人使用以外的目的
                、不論於網路內或網路外再次提供給公眾、未經許可使用及複製等行為。
              </li>
              <li>
                在本網路及官方SNS帳號中顯示的所有商標及服務商標為本公司所有或為本公司依據授權等其他
                合法權源使用之權利，禁止用戶未經本公司許可使用這些商標。
              </li>
              <li>
                不論前述第1項之內容如何，本圖像及本圖像加工後的圖像相關之著作權歸屬於提供本圖像及本
                圖像加工後之圖像予本公司的用戶，但用戶已同意本公司可不限區域，在用戶對本圖像加工處
                理完成後2年期間內如前條所述使用，故用戶對本公司不行使著作人格權。
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第7條</strong>（禁止事項）
            </p>
            <p>本公司禁止用戶在使用本服務時，進行以下所列行為（包含誘發和準備以下行為的行為）。</p>
            <ol>
              <li>中傷及毀謗其他用戶及第三者以及本公司的行為，或可能造成該結果的行為</li>
              <li>侵害其他用戶以及第三者或本公司財產權和隱私的行為，或可能造成侵害的行為</li>
              <li>
                侵害其他用戶以及第三者或本公司智慧財產權（著作權、新式樣專利權、發明專利權、新型專
                利權、商標權、肖像權）之行為，或可能造成侵害的行為
              </li>
              <li>侵害其他用戶以及第三者或本公司之權利的行為，或可能造成侵害的行為</li>
              <li>發送或寫入具危害性的電腦程式等的行為</li>
              <li>違反公共秩序和良好風俗的行為、犯罪行為，或可能造成該結果的行為</li>
              <li>以營利為目的使用本服務的行為，或以準備進行該行為為目的的行為</li>
              <li>選舉活動或類似選舉活動的行為、其他政治及宗教相關的行為</li>
              <li>其他妨礙本服務營運的行為</li>
              <li>違反日本國以及使用本服務時用戶所在國家、地區之法令的行為</li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第8條</strong>（規約之修訂）
            </p>
            <p>
              本公司有權修改本規約內容的全部或一部分，而不事先另行通知用戶。須修訂時，本公司將於本
              網站通知修訂事宜，修訂效力的發生以刊載於本網站之時間為準。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第9條</strong>（服務的變更、追加、終止）
            </p>
            <ol>
              <li>
                本公司在發生以下任一情況時，有權暫時停止或終止提供本服務的全部或一部分，而不事先另
                行通知用戶。
                <ol className="lower-roman">
                  <li>定期或緊急進行本服務之系統的維修、檢查等作業時</li>
                  <li>本服務之系統發生故障時</li>
                  <li>因停電、火災、地震和其他不可抗力因素造成難以提供本服務時</li>
                  <li>其他本服務在運行上或技術上有相當充分的理由時</li>
                </ol>
              </li>
              <li>本公司有權變更、追加、終止本服務內容的全部或一部分，而不事先另行通知用戶。</li>
              <li>即使因前述2項措施而造成用戶發生不利狀況、損害，本公司也概不負責。</li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第10條</strong>（損害賠償）
            </p>
            <p>
              因用戶違反本規約或用戶的不當行為而造成本公司發生損害時，本公司有權對用戶要求相當的損
              害賠償。此外，因用戶使用本服務而造成第三者發生損害時，用戶須在自己的責任下解決該損害。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第11條</strong>（免責）
            </p>
            <ol>
              <li>
                因通信線路或電腦等故障造成的系統中斷、延遲、中止、數據消失及數據的不當存取而使用戶
                產生的損害，本公司概不負責。
              </li>
              <li>
                本公司不保證自包含本網站的本公司網頁、伺服器、網域等發送的郵件、內容不會含有電腦病
                毒等有害之物。
              </li>
              <li>
                包含在SNS的關於本網站的投稿在內，在本網站的用戶之間，或用戶與第三者之間發生糾紛、爭
                訟時，本公司概不負責。
              </li>
              <li>
                因各SNS及相關連的應用程式的運行環境所發生的本網站營運的中斷或中止而造成的任何損害，
                本公司概不負責。
              </li>
            </ol>
          </Section>

          <Section size="small">
            <p>
              <strong>第12條</strong>（準據法及管轄法院）
            </p>
            <p>
              本網站及本規約的解釋及適用以日本國法律為準據法。與本網站相關的一切爭訟，除另有規定外
              ，以東京地方裁判所為第一審專屬管轄法院。
            </p>
          </Section>

          <Section size="small">
            <p>
              <strong>第13條</strong>（相關詢問）
            </p>
            <p>
              本服務相關的詢問，請於資生堂專業髮品官方網站（
              <a
                href="https://www.shiseido-professional.com/zh_tw"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.shiseido-professional.com/zh_tw
              </a>
              ）確認。
            </p>
          </Section>

          <Section size="small">
            <p>2019年2月15日初版制訂</p>
          </Section>
        </React.Fragment>
      );

    default:
      throw Error(`Unrecognized locale: ${locale}`);
  }
};

const mapState = state => ({
  locale: state.locale,
});

export default connect(mapState)(Terms);
