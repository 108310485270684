import PropTypes from 'prop-types';
import React from 'react';

import prevent from 'utils/prevent';
import { Container, Track, Dot } from './Slider.style';

class Slider extends React.PureComponent {
  static defaultProps = {
    disabled: false,
    range: [0, 1],
  };

  static propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    x: PropTypes.number.isRequired,
    range: PropTypes.arrayOf(PropTypes.number),
  };

  constructor(props) {
    super(props);
    this.dot = React.createRef();
    this.container = React.createRef();
  }

  componentDidMount = () => {
    this.dot.current.addEventListener('touchmove', prevent);
  };

  componentWillUnmount = () => {
    this.dot.current.removeEventListener('touchmove', prevent);
  };

  handleTouchMove = e => {
    const { disabled, onChange, range } = this.props;
    if (!disabled) {
      const { x } = this.props;
      const { pageX } = e.targetTouches[0];

      const dot = this.dot.current;
      const container = this.container.current;
      const dx = (pageX - dot.getBoundingClientRect().left - 15) / container.clientWidth;

      const newX = Math.min(Math.max(x + dx, 0), 1);
      if (x !== newX) {
        onChange(range[0] + (range[1] - range[0]) * newX);
      }
    }
  };

  render = () => {
    const { disabled, x } = this.props;

    return (
      <Container ref={this.container} disabled={disabled}>
        <Track>
          <Dot
            ref={this.dot}
            position={x}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            disabled={disabled}
          />
        </Track>
      </Container>
    );
  };
}

export default Slider;
