import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

//import { GalleryPost } from 'components';
import { Container, Posts1, Posts2, Posts3 } from './ScrollingBackground.style';

const ScrollingBackground = ({ posts, speed }) => (
  <Container>
    <Posts1 index={1} speed={speed}>
        <div className="user-container">
            <div className="overlay --red"></div>
            <img src="//images.ctfassets.net/ifb625awchhl/7imGUblSDaBN9Qnwngnzmk/d762904cc7b0c25f6f221ff21d3993e1/naopis.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
            <img src="//images.ctfassets.net/ifb625awchhl/7imGUblSDaBN9Qnwngnzmk/d762904cc7b0c25f6f221ff21d3993e1/naopis.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />
        </div>
        <div className="hash-container">
            <div className="overlay --red"></div>
            <p className="hash-label">#PRIMIENCE</p>
        </div>
        <div className="hash-container">
            <div className="overlay --orange"></div>
            <p className="hash-label">#ColorMixology</p>
        </div>
        <div className="user-container">
            <div className="overlay --orange"></div>
            <img src="//images.ctfassets.net/ifb625awchhl/3QeKDrEuyG19QQYGArUr17/28c10310c5ab9061307225b18518c644/mariru.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
            <img src="//images.ctfassets.net/ifb625awchhl/3QeKDrEuyG19QQYGArUr17/28c10310c5ab9061307225b18518c644/mariru.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />

        </div>
        <div className="user-container">
            <div className="overlay --pink"></div>
            <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
            <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />
        </div>
        <div className="hash-container">
            <div className="overlay --pink"></div>
            <p className="hash-label">#BeAnyColor</p>
        </div>
        <div className="hash-container">
            <div className="overlay --blue"></div>
            <p className="hash-label">#PRIMIENCE</p>
        </div>
        <div className="user-container">
            <div className="overlay --blue"></div>
            <img src="//images.ctfassets.net/ifb625awchhl/6ReMbKgYhNTcFkLsIjA8AJ/422e876e44d07d1f0eb2db5c0f680808/yoshiko.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
            <img src="//images.ctfassets.net/ifb625awchhl/6ReMbKgYhNTcFkLsIjA8AJ/422e876e44d07d1f0eb2db5c0f680808/yoshiko.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />

        </div>
        <div className="user-container">
            <div className="overlay --purple"></div>
            <img src="//images.ctfassets.net/ifb625awchhl/3D95c5Zz9dDIdmQrkzCBGl/8b84775f3518a49969f2255cbf335cec/reina.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
            <img src="//images.ctfassets.net/ifb625awchhl/3D95c5Zz9dDIdmQrkzCBGl/8b84775f3518a49969f2255cbf335cec/reina.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />

        </div>
        <div className="hash-container">
            <div className="overlay --purple"></div>
            <p className="hash-label">#ColorMixology</p>
        </div>
        <div className="hash-container">
            <div className="overlay --rose"></div>
            <p className="hash-label">#BeAnyColor</p>
        </div>
        <div className="user-container">
            <div className="overlay --rose"></div>
            <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
            <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />
        </div>
    </Posts1>
    <Posts2 index={2} speed={speed}>
      <div className="user-container">
          <div className="overlay --red"></div>
          <img src="//images.ctfassets.net/ifb625awchhl/7imGUblSDaBN9Qnwngnzmk/d762904cc7b0c25f6f221ff21d3993e1/naopis.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
          <img src="//images.ctfassets.net/ifb625awchhl/7imGUblSDaBN9Qnwngnzmk/d762904cc7b0c25f6f221ff21d3993e1/naopis.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />
      </div>
      <div className="hash-container">
          <div className="overlay --red"></div>
          <p className="hash-label">#PRIMIENCE</p>
      </div>
      <div className="hash-container">
          <div className="overlay --orange"></div>
          <p className="hash-label">#ColorMixology</p>
      </div>
      <div className="user-container">
          <div className="overlay --orange"></div>
          <img src="//images.ctfassets.net/ifb625awchhl/3QeKDrEuyG19QQYGArUr17/28c10310c5ab9061307225b18518c644/mariru.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
          <img src="//images.ctfassets.net/ifb625awchhl/3QeKDrEuyG19QQYGArUr17/28c10310c5ab9061307225b18518c644/mariru.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />

      </div>
      <div className="user-container">
          <div className="overlay --pink"></div>
          <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
          <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />
      </div>
      <div className="hash-container">
          <div className="overlay --pink"></div>
          <p className="hash-label">#BeAnyColor</p>
      </div>
      <div className="hash-container">
          <div className="overlay --blue"></div>
          <p className="hash-label">#PRIMIENCE</p>
      </div>
      <div className="user-container">
          <div className="overlay --blue"></div>
          <img src="//images.ctfassets.net/ifb625awchhl/6ReMbKgYhNTcFkLsIjA8AJ/422e876e44d07d1f0eb2db5c0f680808/yoshiko.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
          <img src="//images.ctfassets.net/ifb625awchhl/6ReMbKgYhNTcFkLsIjA8AJ/422e876e44d07d1f0eb2db5c0f680808/yoshiko.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />

      </div>
      <div className="user-container">
          <div className="overlay --purple"></div>
          <img src="//images.ctfassets.net/ifb625awchhl/3D95c5Zz9dDIdmQrkzCBGl/8b84775f3518a49969f2255cbf335cec/reina.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
          <img src="//images.ctfassets.net/ifb625awchhl/3D95c5Zz9dDIdmQrkzCBGl/8b84775f3518a49969f2255cbf335cec/reina.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />

      </div>
      <div className="hash-container">
          <div className="overlay --purple"></div>
          <p className="hash-label">#ColorMixology</p>
      </div>
      <div className="hash-container">
          <div className="overlay --rose"></div>
          <p className="hash-label">#BeAnyColor</p>
      </div>
      <div className="user-container">
          <div className="overlay --rose"></div>
          <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
          <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />
      </div>
      {/* {posts.map((post, i) => (
        <GalleryPost key={post.entryId} post={post} i={i} />
      ))} */}
    </Posts2>
    <Posts3 index={3} speed={speed}>
      <div className="user-container">
          <div className="overlay --red"></div>
          <img src="//images.ctfassets.net/ifb625awchhl/7imGUblSDaBN9Qnwngnzmk/d762904cc7b0c25f6f221ff21d3993e1/naopis.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
          <img src="//images.ctfassets.net/ifb625awchhl/7imGUblSDaBN9Qnwngnzmk/d762904cc7b0c25f6f221ff21d3993e1/naopis.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />
      </div>
      <div className="hash-container">
          <div className="overlay --red"></div>
          <p className="hash-label">#PRIMIENCE</p>
      </div>
      <div className="hash-container">
          <div className="overlay --orange"></div>
          <p className="hash-label">#ColorMixology</p>
      </div>
      <div className="user-container">
          <div className="overlay --orange"></div>
          <img src="//images.ctfassets.net/ifb625awchhl/3QeKDrEuyG19QQYGArUr17/28c10310c5ab9061307225b18518c644/mariru.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
          <img src="//images.ctfassets.net/ifb625awchhl/3QeKDrEuyG19QQYGArUr17/28c10310c5ab9061307225b18518c644/mariru.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />

      </div>
      <div className="user-container">
          <div className="overlay --pink"></div>
          <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
          <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />
      </div>
      <div className="hash-container">
          <div className="overlay --pink"></div>
          <p className="hash-label">#BeAnyColor</p>
      </div>
      <div className="hash-container">
          <div className="overlay --blue"></div>
          <p className="hash-label">#PRIMIENCE</p>
      </div>
      <div className="user-container">
          <div className="overlay --blue"></div>
          <img src="//images.ctfassets.net/ifb625awchhl/6ReMbKgYhNTcFkLsIjA8AJ/422e876e44d07d1f0eb2db5c0f680808/yoshiko.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
          <img src="//images.ctfassets.net/ifb625awchhl/6ReMbKgYhNTcFkLsIjA8AJ/422e876e44d07d1f0eb2db5c0f680808/yoshiko.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />

      </div>
      <div className="user-container">
          <div className="overlay --purple"></div>
          <img src="//images.ctfassets.net/ifb625awchhl/3D95c5Zz9dDIdmQrkzCBGl/8b84775f3518a49969f2255cbf335cec/reina.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
          <img src="//images.ctfassets.net/ifb625awchhl/3D95c5Zz9dDIdmQrkzCBGl/8b84775f3518a49969f2255cbf335cec/reina.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />

      </div>
      <div className="hash-container">
          <div className="overlay --purple"></div>
          <p className="hash-label">#ColorMixology</p>
      </div>
      <div className="hash-container">
          <div className="overlay --rose"></div>
          <p className="hash-label">#BeAnyColor</p>
      </div>
      <div className="user-container">
          <div className="overlay --rose"></div>
          <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
          <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />
      </div>
      {/* {posts.map((post, i) => (
        <GalleryPost key={post.entryId} post={post} i={i} />
      ))} */}
    </Posts3>
  </Container>
);

ScrollingBackground.defaultProps = {
  posts: [],
  speed: 120,
};

ScrollingBackground.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape()),
  speed: PropTypes.number,
};

const mapState = state => ({
  posts: state.posts.data.slice(0, 6),
});

export default connect(mapState)(ScrollingBackground);
