import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { ImageDisplay } from 'components';
import { Box, Lid } from './DownloadBox.style';

const DownloadBox = ({ info, upload }) => (
  <Box>
    <ImageDisplay bar={!upload} download {...info} />
    <Lid />
  </Box>
);

DownloadBox.defaultProps = {
  upload: false,
};

DownloadBox.propTypes = {
  info: PropTypes.shape().isRequired,
  upload: PropTypes.bool,
};

const mapState = state => ({
  info: state.info,
});

export default connect(mapState)(DownloadBox);
