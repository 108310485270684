import PropTypes from 'prop-types';
import React from 'react';

import { Container, Content, PaneLabel } from './CarouselPane.style';

const CarouselPane = ({ children, label }) => (
  <Container>
    <PaneLabel>{label}</PaneLabel>
    <Content>{children}</Content>
  </Container>
);

CarouselPane.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired,
  label: PropTypes.string.isRequired,
};

export default CarouselPane;
