import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import gradients from 'assets/gradients.json';
import { GalleryPostPopup } from 'components';
import {
  Container,
  Image,
  Placeholder,
  GradientContainer,
  Gradient,
  Hashtag,
} from './GalleryPost.style';

const hashtags = ['#PRIMIENCE', '#ColorMixologyxxxx', '#BeAnyColor'];

class GalleryPost extends React.PureComponent {
  static propTypes = {
    post: PropTypes.shape({
      image: PropTypes.shape({ file: PropTypes.shape({ url: PropTypes.string }) }),
    }).isRequired,
    i: PropTypes.number.isRequired,
  };

  state = {
    loaded: false,
    show: false,
  };

  showPopup = () => {
    this.setState({ show: true });
  };

  hidePopup = () => {
    this.setState({ show: false });
  };

  imageLoaded = () => {
    this.setState({ loaded: true });
  };

  render = () => {
    const { post, i } = this.props;
    const { loaded, show } = this.state;
    const baseUrl = post.image && post.image.file ? post.image.file.url : '';

    const userPost = (
      <Container>
        <Gradient background={gradients[(i % (gradients.length - 1)) + 1]} />
        <Image src={`${baseUrl}?w=400`} onClick={this.showPopup} onLoad={this.imageLoaded} />
        <Placeholder src={`${baseUrl}?w=20`} hide={loaded} />
        <CSSTransition in={show} timeout={200} classNames="fade-zoom" unmountOnExit>
          <GalleryPostPopup src={baseUrl} hide={this.hidePopup} />
        </CSSTransition>
      </Container>
    );

    const sidePost = (
      <GradientContainer>
        <Gradient background={gradients[(i % (gradients.length - 1)) + 1]} />
        <Hashtag>{hashtags[i % 3]}</Hashtag>
      </GradientContainer>
    );

    return i % 2 === 0 ? (
      <React.Fragment>
        {userPost}
        {sidePost}
      </React.Fragment>
    ) : (
      <React.Fragment>
        {sidePost}
        {userPost}
      </React.Fragment>
    );
  };
}

export default GalleryPost;
