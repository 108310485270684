import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import copy from 'assets/copy.json';
import hasProfanity from 'utils/hasProfanity';
import { Container, Input, Error } from './TextInput.style';

const limit = {
  en: 20,
  ja_jp: 8,
  zh_cn: 8,
  zh_tw: 8,
  zh_hk: 8,
};

class TextInput extends React.PureComponent {
  static propTypes = {
    i: PropTypes.number.isRequired,
    locale: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.shape({
      profanity: PropTypes.bool.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  handleChange = e => {
    const { locale, onChange } = this.props;
    const text = e.target.value.replace(/^ */, '').replace(/ {2}$/, ' ');
    if (text.length <= (limit[locale] || 10)) {
      onChange({ profanity: hasProfanity(text), text });
    }
  };

  handleKeyDown = e => {
    if (e.which === 13) {
      this.input.current.blur();
    }
  };

  render = () => {
    const { i, locale, text } = this.props;

    return (
      <Container>
        <Input
          locale={locale}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          placeholder={copy.placeholders[locale][i]}
          ref={this.input}
          type="text"
          value={text.text}
        />
        {text.profanity && <Error>{copy.profanity[locale]}</Error>}
      </Container>
    );
  };
}

const mapState = state => ({
  locale: state.locale,
});

export default connect(mapState)(TextInput);
