import styled from 'styled-components';

import gradients from 'assets/gradients.json';

const size = 200;
const fonts = {
  en: 'inherit',
  ja: 'Noto Sans JP',
  zh: 'inherit',
};

export const Container = styled.div`
  background-color: #888;
  overflow: hidden;
  position: relative;
  padding-bottom: ${({ download }) => (download ? `${size}px` : '100%')};
  width: ${({ download }) => (download ? `${size}px` : '100%')};

`;

export const DownloadImage = styled.img.attrs(({ ar, scale, x, y }) => {
  const ϴx = Math.max(1, ar);
  const ϴy = Math.max(1, 1 / ar);
  const ϴz = scale + 0.01;

  return {
    style: {
      /* eslint-disable */
      height: size * ϴz * ϴy,
      width:  size * ϴz * ϴx,
      top:    size * ((1 - ϴz * ϴy) / 2 + y * ϴy),
      left:   size * ((1 - ϴz * ϴx) / 2 + x * ϴx),
      /* eslint-enable */
    },
  };
})`
  position: absolute;
`;

export const Image = styled.img.attrs(({ scale, shadow, x, y }) => ({
  style: {
    filter: `brightness(${1 - shadow / 2})`,
    transform: `translate(${x * 100}%, ${y * 100}%) translate(-50%, -50%) scale(${scale + 0.01})`,
  },
}))`
  position: absolute;
  left: 50%;
  top: 50%;

  height: ${({ ar }) => (ar > 1 ? '100%' : 'unset')};
  width: ${({ ar }) => (ar <= 1 ? '100%' : 'unset')};
`;

export const Shadow = styled.div.attrs(({ shadow }) => ({
  //style: { opacity: shadow / 2 },
  style: { backgroundColor: 'rgba(0, 0, 0, ' + shadow / 2 + ')' },
}))`
  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;

`;

export const Gradient = styled.div`
  position: absolute;
  right: -1px;
  top: -1px;

  background: ${({ background }) => gradients[background]};

  height: 102%;
  width: 11%;
`;

// For custom background
// export const Gradient = styled.div`
//   position: absolute;
//   right: 0;
//   top: 0;

//   background: ${({ background }) => gradients[background]};
//   background-size: 100% 100%;

//   height: 100%;
//   width: 100%;
// `;

export const Texts = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 16px;
  padding: 2%;
`;

// For Custom Background
// export const Texts = styled.div`
//   position: absolute;
//   left: 0;
//   /*top: 0;*/
//   bottom: 20%;

//   height: 100%;
//   width: 100%;

//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   font-size: 16px;
//   padding: 2%;
// `;

export const Text = styled.text`
  fill: white;
  font-family: ${({ locale }) => fonts[locale]};
  font-size: 100%;
  font-weight: 400;
  letter-spacing: 0px;
  opacity: ${({ empty }) => (empty ? 0.5 : 1)};
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.6);
`;

export const DownloadText = styled.p`
  color: white;
  font-family: ${({ locale }) => fonts[locale]};
  font-size: 100%;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1.3;
  margin: 0 0 0 2px;
  text-shadow: 0.55px 0.55px 0 rgba(0, 0, 0, 0.4);
`;

export const Hashtag = styled.text`
  fill: white;
  font-size: 44%;
  font-weight: 400;
  white-space: pre;
  letter-spacing: 0;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5);
`;

export const DownloadHashtag = styled.p`
  color: white;
  font-size: 41.8%;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0px;
  margin: 3px 0 0 3px;
  white-space: pre;
  text-shadow: 0.55px 0.55px 0 rgba(0, 0, 0, 0.4);
`;

export const Logo = styled.img`
  width: 96px;
  height: 29px;
  margin: 8px 0 7px 2px;
`;

export const DownloadLogo = styled.img`
  width: 58px;
  height: 19px;
  margin: 5px 2px 3px;
`;

export const Product = styled.img`
  width: 78px;
  height: 87px;
  position: absolute;
  right: 16%;
  bottom: 0;
`;

export const DownloadProduct = styled.img`
  width: 49px;
  height: 55px;
  position: absolute;
  right: 16%;
  bottom: -2px;
`;