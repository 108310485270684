const prevent = e => e.preventDefault();

export const lockScroll = () => {
  document.body.style.position = 'fixed';
};

export const unlockScroll = () => {
  document.body.style.position = 'relative';
};

export const lockTouch = () => {
  window.addEventListener('touchmove', prevent, { passive: false });
};

export const unlockTouch = () => {
  window.removeEventListener('touchmove', prevent, { passive: false });
};
