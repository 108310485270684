import styled from 'styled-components';

const fonts = {
  en: 'inherit',
  ja: 'Noto Sans JP',
  zh: 'inherit',
};

export const Container = styled.div`
  padding-bottom: 5vw;
  position: relative;
  width: 100%;
`;

export const Input = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
  color: black;
  font-family: ${({ locale }) => fonts[locale]};
  font-size: 8vw;
  font-weight: 400;
  letter-spacing: -1px;
  padding: 0;
  text-align: center;
  width: 100%;

  &::placeholder {
    color: #ddd;
  }
`;

export const Error = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;

  color: red;
  font-size: 3vw;
  margin: 0;
  text-align: center;
  width: 100%;
`;
