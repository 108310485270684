//import { addPost, getPosts } from 'services/contentful';
//import { addPost } from 'services/contentful';

import posts from 'assets/posts.json';

// export const addPostAction = post => ({
//   type: 'ADD_POST',
//   payload: addPost(post),
// });

//console.log('posts', posts)

export const getPostsAction = page => ({
  type: 'GET_POSTS',
  payload: posts,
});
