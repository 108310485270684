import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { CloseButton } from 'components/Buttons';
import { Container, Locale } from './LocaleSelector.style';

const availableLocales = [
  { code: 'en', name: 'English' },
  { code: 'ja_jp', name: '日本語' },
  // { code: 'ko_kr', name: '한국어' },
  { code: 'zh_cn', name: '简体中文' },
  { code: 'zh_tw', name: '繁體中文' },
  // { code: 'zh_hk', name: '繁體中文' },
];

class LocaleSelector extends React.PureComponent {
  static defaultProps = {
    visible: false,
  };

  static propTypes = {
    hide: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    updateLocale: PropTypes.func.isRequired,
    visible: PropTypes.bool,
  };

  handleClose = () => {
    const { hide } = this.props;
    hide();
  };

  updateLocale = code => () => {
    const { locale, updateLocale } = this.props;
    if (locale !== code) updateLocale(code);
    this.handleClose();
  };

  render = () => {
    const { visible } = this.props;

    return (
      <Container visible={visible}>
        <CloseButton onClick={this.handleClose} />
        {availableLocales.map(({ code, name }) => (
          <Link key={code} to={`/${code}/color-mixology`}>
            <Locale onClick={this.updateLocale(code)}>{name}</Locale>
          </Link>
        ))}
      </Container>
    );
  };
}

const mapState = state => ({
  locale: state.locale,
});

const mapDisaptch = dispatch => ({
  updateLocale: code =>
    dispatch({
      type: 'UPDATE_LOCALE',
      payload: code,
    }),
});

export default connect(
  mapState,
  mapDisaptch
)(LocaleSelector);
