import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  background-color: black;
  padding: 40px;
`;

export const Locale = styled.div`
  color: white;
  font-size: 4vw;
  padding: 20px 0;
  position: relative;
  width: 100%;
`;
