import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  margin: 0;
  min-height: 35vw;
  padding: 5vw 5vw 0;
  width: 100vw;

  &:first-child {
    margin-left: 0;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
`;

export const PaneLabel = styled.p`
  color: black;
  font-size: 4.3vw;
  margin: 0;
  padding: 0;
  text-align: center;
`;
