import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import { App } from './components';
import configureStore from 'redux/configureStore';
import initialState from 'redux/initialState';

const store = configureStore(initialState);

const Root = () => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/:locale(en|ja_jp|zh_cn|zh_tw)/color-mixology" component={App} />
        <Redirect to="/en/color-mixology" />
      </Switch>
    </Router>
  </Provider>
);

export default Root;
