import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000;
`;

const spin = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 1px solid black;
  border-color: black transparent black black;
  border-radius: 50%;
  height: 20px;
  width: 20px;

  animation: 0.5s linear infinite ${spin};
`;

export const Text = styled.p`
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
`;
