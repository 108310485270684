import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import copy from 'assets/copy.json';
import correctOrientation from 'utils/correctOrientation';
import { Label, Input } from './ImageSelector.style';

class ImageSelector extends React.PureComponent {
  static propTypes = {
    imageLoaded: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  handleChange = () => {
    const { onSelect } = this.props;
    const file = this.input.current.files[0];
    if (file) {
      const fr = new FileReader();
      fr.onload = e => {
        onSelect(e.target.result);
        correctOrientation(file, image => onSelect(image));
      };
      fr.readAsDataURL(file);
    }
  };

  render = () => {
    const { imageLoaded, locale } = this.props;

    return (
      <Label>
        <Input ref={this.input} type="file" accept="image/*" onChange={this.handleChange} />
        {imageLoaded ? copy.changeImage[locale] : copy.selectImage[locale]}
      </Label>
    );
  };
}

const mapState = state => ({
  locale: state.locale,
});

export default connect(mapState)(ImageSelector);
