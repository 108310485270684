import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  height: 10%;
  position: relative;
  width: 100%;
`;

export const Track = styled.div`
  width: ${({ count }) => `${count * 100}vw`};
  height: 100%;
  position: absolute;
  left: -5vw;
  top: 0;

  display: flex;
  justify-content: space-between;
  transform: ${({ position }) => `translateX(${-100 * position}vw)`};
  transition: transform 300ms ease-in-out;
`;
