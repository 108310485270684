import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 10;
`;

export const ButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 20px;
  left: 0;

  display: flex;
  justify-content: center;
  transform: ${({ show }) => `translateY(${show ? '0' : '150%'})`};
  transition: transform 300ms ease-in-out;
`;

const spin = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  background-color: white;
  height: 100%;
  padding-bottom: 100%;
  position: relative;

  &::before {
    animation: 0.5s linear infinite ${spin};
    border-radius: 50%;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid black;
    border-color: black black black transparent;
    height: 10%;
    width: 10%;
  }
`;
