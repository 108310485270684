import styled from 'styled-components';

const ShareIcon = styled.div`
  color: #000;
  position: absolute;
  border: solid 1px currentColor;
  border-top: none;
  margin-top: 8px;
  height: 6px;
  width: 21px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 9px;
    width: 1px;
    height: 9px;
    background-color: currentColor;
  }

  &::after {
    content: '';
    position: absolute;
    top: -8px;
    left: 7px;
    width: 4px;
    height: 4px;
    border-top: solid 1px currentColor;
    border-right: solid 1px currentColor;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    }
`;

export default ShareIcon;
