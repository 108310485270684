import React from 'react';
import { connect } from 'react-redux';

import { Title, Section } from './TextModal.style';

const Privacy = ({ locale }) => {
  switch (locale) {
    case 'en':
      return (
        <React.Fragment>
          <Title>Privacy Policy</Title>
          <Section size="small">
            <p>Description</p>
          </Section>
        </React.Fragment>
      );

    case 'ja_jp':
      return (
        <React.Fragment>
          <Title>Privacy Policy</Title>
          <Section size="small">
            <p>Description</p>
          </Section>
        </React.Fragment>
      );

    case 'ko_kr':
      return (
        <React.Fragment>
          <Title>Privacy Policy</Title>
          <Section size="small">
            <p>Description</p>
          </Section>
        </React.Fragment>
      );

    case 'zh_cn':
      return (
        <React.Fragment>
          <Title>Privacy Policy</Title>
          <Section size="small">
            <p>Description</p>
          </Section>
        </React.Fragment>
      );

    case 'zh_tw':
      return (
        <React.Fragment>
          <Title>Privacy Policy</Title>
          <Section size="small">
            <p>Description</p>
          </Section>
        </React.Fragment>
      );

    case 'zh_hk':
      return (
        <React.Fragment>
          <Title>Privacy Policy</Title>
          <Section size="small">
            <p>Description</p>
          </Section>
        </React.Fragment>
      );

    default:
      throw Error(`Unrecognized locale: ${locale}`);
  }
};

const mapState = state => ({
  locale: state.locale,
});

export default connect(mapState)(Privacy);
