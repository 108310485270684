import { combineReducers } from 'redux';

import info from './info';
import locale from './locale';
import posts from './posts';

const root = combineReducers({
  info,
  locale,
  posts,
  screenSize: () => ({
    height: window.innerHeight,
    width: window.innerWidth,
  }),
});

export default root;
