import styled from 'styled-components';

const BackIcon = styled.div`
  height: 20px;
  width: 20px;
  overflow: hidden;
  position: relative;

  &::before {
    background-color: ${({ black }) => (black ? 'black' : 'white')};
    content: '';
    position: absolute;
    height: 1px;
    width: 200%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    background-color: ${({ black }) => (black ? 'black' : 'white')};
    content: '';
    position: absolute;
    height: 1px;
    width: 200%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export default BackIcon;
