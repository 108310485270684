import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding-bottom: 100%;
  width: 100%;
`;

export const Image = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  object-fit: cover;
  display: block;
`;

const spin = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const Spinner = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 10%;
  width: 10%;

  animation: 0.5s linear infinite ${spin};
  border: 1px solid white;
  border-color: white transparent white white;
  border-radius: 50%;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 200ms ease-in-out;
`;

export const Placeholder = styled.img`
  position: absolute;
  left: -1%;
  top: -1%;
  height: 102%;
  width: 102%;

  filter: blur(0.5vw);
  object-fit: cover;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 200ms ease-in-out;
`;

export const Popup = styled.div`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

export const Frame = styled.div`
  background-color: white;
  border-radius: 1%;
  left: 50%;
  padding: 3%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
`;

export const Shadow = styled.div`
  background-color: black;
  height: 100%;
  opacity: 0.5;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
`;
