const getDataUrl = (file, callback2) => {
    const callback = srcOrientation => {
      const reader2 = new FileReader();
      reader2.onload = e => {
        const srcBase64 = e.target.result;
        const img = new Image();
  
        img.onload = () => {
          const { height, width } = img;
  
          const canvas = document.createElement('canvas');
  
          const ctx = canvas.getContext('2d');
  
          // set proper canvas dimensions before transform & export
          if (srcOrientation > 4 && srcOrientation < 9) {
            canvas.width = height;
            canvas.height = width;
          } else {
            canvas.width = width;
            canvas.height = height;
          }
  
          // transform context before drawing image
          switch (srcOrientation) {
            case 2:
              ctx.transform(-1, 0, 0, 1, width, 0);
              break;
            case 3:
              ctx.transform(-1, 0, 0, -1, width, height);
              break;
            case 4:
              ctx.transform(1, 0, 0, -1, 0, height);
              break;
            case 5:
              ctx.transform(0, 1, 1, 0, 0, 0);
              break;
            case 6:
              ctx.transform(0, 1, -1, 0, height, 0);
              break;
            case 7:
              ctx.transform(0, -1, -1, 0, height, width);
              break;
            case 8:
              ctx.transform(0, -1, 1, 0, 0, width);
              break;
            default:
              break;
          }
  
          // draw image
          ctx.drawImage(img, 0, 0);
  
          // export base64
          callback2(canvas.toDataURL());
        };
  
        img.src = srcBase64;
      };
  
      reader2.readAsDataURL(file);
    };
  
    const reader = new FileReader();
    reader.onload = e => {
      const view = new DataView(e.target.result);
      if (view.getUint16(0, false) !== 0xffd8) return callback(-2);
      const length = view.byteLength;
  
      let offset = 2;
      while (offset < length) {
        const marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) != 0x45786966) { // eslint-disable-line
            return callback(-1);
          }
          const little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i += 1)
            if (view.getUint16(offset + i * 12, little) === 0x0112)
              return callback(view.getUint16(offset + i * 12 + 8, little));
        } else if ((marker & 0xff00) != 0xff00) break; // eslint-disable-line
        else offset += view.getUint16(offset, false);
      }
      return callback(-1);
    };
    reader.readAsArrayBuffer(file);
  };
  
  export default getDataUrl;
  