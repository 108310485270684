import { applyMiddleware, createStore } from 'redux';
// import promiseMiddleware from 'redux-promise-middleware';
import reduxPromise from 'redux-promise';

import reducer from './reducers';

const middlewares = [ reduxPromise ];

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger'); // eslint-disable-line
  middlewares.push(createLogger());
}

const configureStore = initialState =>
  createStore(reducer, initialState, applyMiddleware(...middlewares));

export default configureStore;
