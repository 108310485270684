const initialState = {
    background: 0,
    image: {
      ar: 1,
      scale: 1,
      src: '',
      x: 0,
      y: 0,
    },
    shadow: 0,
    texts: [{ id: 1, text: 'asdf' }, { id: 2, text: 'asdf' }, { id: 3, text: 'asdf' }],
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case 'UPDATE_INFO':
        return payload;
  
      default:
        return state;
    }
  };
  