import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import copy from 'assets/copy.json';
import { getPostsAction } from 'redux/actions/posts';

// import { GalleryPost } from 'components';
import { WhiteButton } from 'components/Buttons';
import { Container, ButtonContainer, Loading } from './Gallery.style';

class Gallery extends React.PureComponent {
  static propTypes = {
    back: PropTypes.func.isRequired,
    getPosts: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    posts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  state = {
    scroll: 0,
    showButton: true,
  };

  componentWillMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll = () => {
    const { scroll: prevScroll } = this.state;
    const scroll = window.pageYOffset;
    this.setState({ scroll, showButton: scroll <= Math.max(prevScroll, 0) });
  };

  handleLoad = () => {
    const { getPosts, posts } = this.props;
    const page = Math.floor(posts.length / 6);
    getPosts(page);
  };

  handleBackToMain = () => {
    const { back } = this.props;
    back();
  };

  render = () => {
    const { locale, loading } = this.props;
    const { showButton } = this.state;

    return (
      <Container>
        <div className="user-container">
            <div className="overlay --red"></div>
            <img src="//images.ctfassets.net/ifb625awchhl/7imGUblSDaBN9Qnwngnzmk/d762904cc7b0c25f6f221ff21d3993e1/naopis.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
            <img src="//images.ctfassets.net/ifb625awchhl/7imGUblSDaBN9Qnwngnzmk/d762904cc7b0c25f6f221ff21d3993e1/naopis.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />
        </div>
        <div className="hash-container">
            <div className="overlay --red"></div>
            <p className="hash-label">#PRIMIENCE</p>
        </div>
        <div className="hash-container">
            <div className="overlay --orange"></div>
            <p className="hash-label">#ColorMixology</p>
        </div>
        <div className="user-container">
            <div className="overlay --orange"></div>
            <img src="//images.ctfassets.net/ifb625awchhl/3QeKDrEuyG19QQYGArUr17/28c10310c5ab9061307225b18518c644/mariru.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
            <img src="//images.ctfassets.net/ifb625awchhl/3QeKDrEuyG19QQYGArUr17/28c10310c5ab9061307225b18518c644/mariru.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />

        </div>
        <div className="user-container">
            <div className="overlay --pink"></div>
            <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
            <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />
        </div>
        <div className="hash-container">
            <div className="overlay --pink"></div>
            <p className="hash-label">#BeAnyColor</p>
        </div>
        <div className="hash-container">
            <div className="overlay --blue"></div>
            <p className="hash-label">#PRIMIENCE</p>
        </div>
        <div className="user-container">
            <div className="overlay --blue"></div>
            <img src="//images.ctfassets.net/ifb625awchhl/6ReMbKgYhNTcFkLsIjA8AJ/422e876e44d07d1f0eb2db5c0f680808/yoshiko.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
            <img src="//images.ctfassets.net/ifb625awchhl/6ReMbKgYhNTcFkLsIjA8AJ/422e876e44d07d1f0eb2db5c0f680808/yoshiko.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />

        </div>
        <div className="user-container">
            <div className="overlay --purple"></div>
            <img src="//images.ctfassets.net/ifb625awchhl/3D95c5Zz9dDIdmQrkzCBGl/8b84775f3518a49969f2255cbf335cec/reina.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
            <img src="//images.ctfassets.net/ifb625awchhl/3D95c5Zz9dDIdmQrkzCBGl/8b84775f3518a49969f2255cbf335cec/reina.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />

        </div>
        <div className="hash-container">
            <div className="overlay --purple"></div>
            <p className="hash-label">#ColorMixology</p>
        </div>
        <div className="hash-container">
            <div className="overlay --rose"></div>
            <p className="hash-label">#BeAnyColor</p>
        </div>
        <div className="user-container">
            <div className="overlay --rose"></div>
            <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=400" className="sc-hMqMXs eHfxSV" alt="" />
            <img src="//images.ctfassets.net/ifb625awchhl/5mNvfK9IstLZxJGqK3SmFy/cc2331e53735996e3a14cbd51e03389b/tatsuya.jpg?w=20" className="sc-hMqMXs sc-kEYyzF gWRWlT" alt="" />
        </div>
        {/* {posts.map((post, i) => (
          <GalleryPost key={post.entryId} post={post} i={i} />
        ))} */}
        {loading && (
          <React.Fragment>
            <Loading />
            <Loading />
          </React.Fragment>
        )}

        <ButtonContainer show={showButton}>
          <WhiteButton onClick={this.handleBackToMain}>{copy.backToMainMenu[locale]}</WhiteButton>
        </ButtonContainer>
      </Container>
    );
  };
}

const mapState = state => ({
  locale: state.locale,
  posts: state.posts.data,
});

const mapDispatch = dispatch => ({
  getPosts: page => dispatch(getPostsAction(page)),
});

export default connect(
  mapState,
  mapDispatch
)(Gallery);
