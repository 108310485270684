import styled from 'styled-components';

const SaveIcon = styled.div`
  color: #000;
  position: absolute;
  border: solid 1px currentColor;
  border-top: none;
  margin-top: 8px;
  height: 6px;
  width: 21px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 9px;
    top: -9px;
    width: 1px;
    height: 10px;
    background-color: currentColor;
  }

  &::after {
    content: '';
    position: absolute;
    left: 7px;
    top: -4px;
    width: 4px;
    height: 4px;
    border-top: solid 1px currentColor;
    border-right: solid 1px currentColor;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    }
`;

export default SaveIcon;
