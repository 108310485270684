import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import {
  DownloadBox,
  ErrorScreen,
  Gallery,
  LoadingScreen,
  StartScreen,
  WorkBench,
} from 'components';
import { getPostsAction } from 'redux/actions/posts';

class App extends React.PureComponent {
  state = {
    loading: false,
    screen: 'loading',
  };
 
  static propTypes = {
    getPosts: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        locale: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    postsCount: PropTypes.number.isRequired,
    updateLocale: PropTypes.func.isRequired,
  };

  componentWillMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  };

  componentDidMount = async () => {
    const { getPosts, match, updateLocale } = this.props;
    updateLocale(match.params.locale);
    await getPosts();
    this.setState({ screen: 'start' });
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll = () => {
    const { getPosts, postsCount } = this.props;
    const { loading } = this.state;
    const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 200;
    if (atBottom && !loading) {
      this.setState({ loading: true }, async () => {
        const posts = await getPosts(postsCount);
        if (posts.value.length < 6) {
          window.removeEventListener('scroll', this.handleScroll);
        }
        this.setState({ loading: false });
      });
    }
  };

  toScreen = name => () => {
    this.setState({ screen: name });
  };

  render() {
    const { loading, screen } = this.state;

    return (
      <React.Fragment>
        <CSSTransition in={screen === 'loading'} timeout={500} classNames="fade" unmountOnExit>
          <LoadingScreen label="loading" />
        </CSSTransition>

        <CSSTransition in={screen === 'start'} timeout={200} classNames="fade-zoom" unmountOnExit>
          <StartScreen hide={this.toScreen('gallery')} create={this.toScreen('bench')} />
        </CSSTransition>

        <CSSTransition in={screen === 'bench'} timeout={200} classNames="bench" unmountOnExit>
          <WorkBench hide={this.toScreen('start')} />
        </CSSTransition>

        <CSSTransition in={screen === 'gallery'} timeout={300} classNames="gallery" unmountOnExit>
          <Gallery back={this.toScreen('start')} loading={loading} />
        </CSSTransition>

        <ErrorScreen />
        <DownloadBox />
        <DownloadBox upload />
      </React.Fragment>
    );
  }
}

const matState = state => ({
  postsCount: state.posts.data.length,
});

const mapDispatch = dispatch => ({
  getPosts: skip => dispatch(getPostsAction(skip)),
  updateLocale: locale => dispatch({ type: 'UPDATE_LOCALE', payload: locale }),
});

export default connect(
  matState,
  mapDispatch
)(App);
