import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  filter: brightness(0.5);
  height: 101%;
  max-height: 101vh;
  overflow: hidden;
`;

const slide1 = keyframes`
  from { transform: translateY(0) }
  to   { transform: translateY(-100%) }
`;

const slide2 = keyframes`
  from { transform: translateY(0) }
  to   { transform: translateY(-200%) }
`;

const slide3 = keyframes`
  from { transform: translateY(-100%) }
  to   { transform: translateY(-300%) }
`;

const Posts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const Posts1 = styled(Posts)`
  animation: ${slide1} ${({ speed }) => `${speed / 2}s`} 0s linear 1 running;
  opacity: ${({ animate }) => (animate ? 0 : 1)};
  transition: opacity 0s ${({ speed }) => `${speed / 2}s`};
`;

export const Posts2 = styled(Posts)`
  animation: ${slide2} ${({ speed }) => `${speed}s`} 0s linear infinite running;
`;

export const Posts3 = styled(Posts)`
  animation: ${slide3} ${({ speed }) => `${speed}s ${speed / 2}s`} linear infinite running;
`;
